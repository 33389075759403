







































































import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { CalendarDisplayType, useCalendarStore } from '@/store/calendar-store';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import EventCard from '@/components/calendar/EventCard.vue';
import { CalendarEvent } from '@/components/calendar/models/calendar-event';
import moment from 'moment';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    EventCard,
    EmptyDataset
  },
  mixins: [CalendarBase],
  data() {
    return {
      CalendarDisplayType: CalendarDisplayType
    };
  },
  computed: {
    ...mapStores(useCalendarStore),
    calendarEvents(): CalendarEvent[] {
      return this.calendarStore.getCalendarEvents(
        this.selectedEntityId,
        this.date
      );
    },
    nowBarDuringEventIndex(): number {
      if (moment().isSame(this.calendarEvents[0].start, 'day')) {
        return this.calendarEvents.findIndex((calendarEvent: CalendarEvent) => {
          return moment().isBetween(
            moment(calendarEvent.start),
            moment(calendarEvent.end)
          );
        });
      } else {
        return -1;
      }
    },
    nowBarBeforeEventIndex(): number {
      if (
        moment().isSame(this.calendarEvents[0].start, 'day') &&
        this.nowBarDuringEventIndex === -1
      ) {
        return this.calendarEvents.findIndex((calendarEvent: CalendarEvent) => {
          return moment().isBefore(moment(calendarEvent.start));
        });
      } else {
        return -1;
      }
    },
    nowBarAfterAllEvents(): boolean {
      if (moment().isSame(this.calendarEvents[0].start, 'day')) {
        return (
          this.nowBarDuringEventIndex === -1 &&
          this.nowBarBeforeEventIndex === -1
        );
      } else {
        return false;
      }
    }
  }
});
