





























































































import employeeService from '@/services/employee.service';
import Vue, { PropType } from 'vue';
import { mapStores } from 'pinia';
import { CalendarResourceType, useCalendarStore } from '@/store/calendar-store';
import { useEmployeesStore } from '@/store/employees-store';
import { AlertType, useUiStore } from '@/store/ui-store';
import { useServicesStore } from '@/store/services-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useLocationsStore } from '@/store/locations-store';
import { useMachinesStore } from '@/store/machines-store';
import { CalendarEvent } from '@/components/calendar/models/calendar-event';
import { ColorHelper } from '@/helpers/color-helper';
import customerService from '@/services/customer.service';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import { Workplace } from '@/model/workplace';
import { Service } from '@/model/service';
import { Location } from '@/model/location';

export default Vue.extend({
  components: {
    ConfirmModal
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    event: {
      type: Object as PropType<CalendarEvent>,
      required: true
    },
    summary: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sendingData: false,
      ColorHelper: ColorHelper,
      confirmModal: null as IConfirmModal
    };
  },
  computed: {
    ...mapStores(
      useCalendarStore,
      useServicesStore,
      useWorkplacesStore,
      useLocationsStore,
      useEmployeesStore,
      useMachinesStore,
      useUiStore
    ),
    viewType(): CalendarResourceType {
      return this.calendarStore.viewType;
    },
    isBooking(): boolean {
      return Boolean(this.event.booking_id);
    }
  },
  mounted() {
    this.confirmModal = (this.$refs.confirmModal as any) as IConfirmModal;
  },
  methods: {
    getServiceById(id: number): Service {
      return this.servicesStore.getById(id);
    },
    getWorkplaceById(id: number): Workplace {
      return this.workplacesStore.getById(id);
    },
    getLocationById(id: number): Location {
      return this.locationsStore.getById(id);
    },
    async onEdit() {
      // TODO : edit doit être la "ok" action et delete la "cancel"
    },
    showDeleteConfirmModal() {
      if (this.isBooking) {
        this.confirmModal.openModal(this.$t('confirmModal.deleteBooking'), {
          okTitle: this.$t('button.cancelBooking'),
          okVariant: 'danger',
          cancelTitle: this.$t('button.back')
        });
      } else {
        this.confirmModal.openModal(this.$t('confirmModal.deleteEvent'), {
          okTitle: this.$t('button.delete'),
          okVariant: 'danger'
        });
      }
    },
    async onDelete() {
      try {
        this.sendingData = true;
        let entityId;
        if (this.viewType === CalendarResourceType.EMPLOYEE) {
          entityId = this.employeesStore.currentEntity.id;
        } else if (this.viewType === CalendarResourceType.MACHINE) {
          entityId = this.machinesStore.currentEntity.id;
        } else if (this.viewType === CalendarResourceType.WORKPLACE) {
          entityId = this.workplacesStore.currentEntity.id;
        }
        if (this.isBooking) {
          const response = await customerService.cancelBooking(
            this.event.booking_id
          );
          this.calendarStore.deleteEventByBookingId(
            this.event.booking_id,
            entityId
          );
          if (response && response.message) {
            this.uiStore.alert(response.message, AlertType.SUCCESS);
          }
        } else {
          await employeeService.deleteEvent(this.event.event_id);
          this.calendarStore.deleteEvent(this.event.event_id, entityId);
          this.uiStore.alert(this.$t('toast.eventDeleted'), AlertType.SUCCESS);
        }
        this.sendingData = false;
        this.$bvModal.hide('event-modal-' + this.id);
      } catch (exception) {
        console.error(exception);
        this.uiStore.alert(exception.message);
        this.sendingData = false;
      }
    }
  }
});
