


































import Vue, { PropType } from 'vue';
import { mapStores } from 'pinia';
import { useUiStore } from '@/store/ui-store';

export interface FloatingActionButtonAction {
  title: string;
  icon: string;
}

export default Vue.extend({
  props: {
    options: {
      type: Array as PropType<FloatingActionButtonAction[]>,
      required: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    ...mapStores(useUiStore)
  },
  methods: {
    onClickOnButton(event) {
      this.open = !this.open;
      this.uiStore.setBackdropVisibility(this.open);
      this.$nextTick(() => {
        document.querySelector('#backdrop').addEventListener(
          'click',
          () => {
            this.uiStore.setBackdropVisibility(false);
            this.open = false;
          },
          { once: true }
        );
      });
    },
    onClickOnOption(action: FloatingActionButtonAction) {
      this.$emit('actionSelected', action);
      this.uiStore.setBackdropVisibility(false);
      this.open = false;
    }
  }
});
