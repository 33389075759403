





























import FormInputMixin from '@/mixins/FormInputMixin';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  mixins: [FormInputMixin],
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: '',
      required: true
    },
    optional: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    // Permet de passer des event à input depuis le composant parent
    inputListeners() {
      const vm: any = this;
      return Object.assign({}, this.$listeners, {
        input(event) {
          vm.$emit('onValueChange', event.target.value);
        }
      });
    }
  }
});
