





























































import CalendarEventAddModal from '@/components/calendar/CalendarEventAddModal.vue';
import TimetableExceptionForm from '@/components/timetables/TimetableExceptionForm.vue';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import Vue, { VueConstructor } from 'vue';
import { Employee } from '@/model/employee';
import {
  CalendarDisplayType,
  CalendarResourceType
} from '@/store/calendar-store';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useVendorsStore } from '@/store/vendors-store';
import { useUsersStore } from '@/store/users-store';
import CalendarPlanning from '@/components/calendar/CalendarPlanning.vue';
import CalendarGrid from '@/components/calendar/CalendarGrid.vue';
import FloatingActionButton, {
  FloatingActionButtonAction
} from '@/components/shared/FloatingActionButton.vue';
import { API_DATE_FORMAT } from '@/constants';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    TimetableExceptionForm,
    CalendarEventAddModal,
    CalendarPlanning,
    CalendarGrid,
    FloatingActionButton
  },
  mixins: [CalendarBase],
  data() {
    return {
      VIEW_TYPE: CalendarResourceType,
      CalendarDisplayType: CalendarDisplayType,
      eventAddOptions: [
        { icon: 'calendar-plus', title: 'RDV client' },
        { icon: 'calendar-star', title: 'RDV privé' },
        { icon: 'coffee', title: 'Pause' },
        { icon: 'car', title: 'Déplacement' }
      ] as FloatingActionButtonAction[],
      API_DATE_FORMAT: API_DATE_FORMAT,
      eventAddProps: {
        bookingView: false,
        summary: ''
      }
    };
  },
  computed: {
    ...mapStores(useEmployeesStore, useVendorsStore, useUsersStore),
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    isFetchingVendor(): boolean {
      return this.vendorsStore.fetching;
    },
    loggedInVendorId(): number {
      return this.usersStore.loggedInVendorId as number;
    },
    employee(): Employee {
      return this.employeesStore.getById(this.selectedEmployeeId);
    },
    canAddEventsOutOfSchedule(): boolean {
      return (
        this.viewType !== CalendarResourceType.EMPLOYEE ||
        (this.employeesStore.getById(this.selectedEntityId) &&
          this.employeesStore.getById(this.selectedEntityId)
            .events_out_of_schedule)
      );
    },
    eventOnly(): boolean {
      return this.viewType !== CalendarResourceType.EMPLOYEE;
    }
  },
  methods: {
    addEvent(action: FloatingActionButtonAction) {
      if (action.title === this.eventAddOptions[0].title) {
        this.eventAddProps.bookingView = true;
      } else {
        if (action.title !== this.eventAddOptions[1].title) {
          this.eventAddProps.summary = action.title;
        } else {
          this.eventAddProps.summary = '';
        }
        this.eventAddProps.bookingView = false;
      }
      this.$bvModal.show('event-add-modal--1');
    },
    saveTimetableException(modalEvent) {
      modalEvent.preventDefault();
      (this.$refs.timetableExceptionForm as any).save();
    },
    timetableExceptionSaved() {
      this.$bvModal.hide('create-timetable-exception-modal');
      this.fetchSchedule();
      this.fetchEvents();
    }
  }
});
