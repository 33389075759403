var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-p-0.5",staticStyle:{"min-height":"18px"},style:({
    height: _vm.customHeightInPx + 'px',
    'z-index': _vm.zIndex
  }),on:{"click":function($event){return _vm.cardClicked()}}},[_c('div',{staticClass:"tw-relative tw-cursor-pointer tw-h-full tw-bg-right-bottom tw-bg-no-repeat tw-text-sm",class:[
      {
        'tw-p-4 tw-mb-2': _vm.mode === _vm.CalendarDisplayType.PLANNING,
        'tw-px-2 tw-py-1':
          (_vm.mode === _vm.CalendarDisplayType.DAY_GRID ||
            _vm.mode === _vm.CalendarDisplayType.WEEK_GRID) &&
          _vm.maxDisplayedLines > 0,
        'tw-text-white': _vm.lightText,
        'tw-rounded-sm': _vm.customHeightInPx > 15,
        'tw-rounded':
          _vm.customHeightInPx > 30 || _vm.mode === _vm.CalendarDisplayType.PLANNING,
        'tw-border tw-border-gray-200': !_vm.calendarEvent.workplace_id
      },
      _vm.backgroundColor
    ]},[(!_vm.calendarEvent.booking_id)?_c('div',{staticClass:"tw-absolute z-0 tw-h-full tw-w-full tw-top-0 tw-left-0 bg-no-workplace"}):_vm._e(),_c('div',{staticClass:"tw-relative"},[_c('calendar-event-add-modal',{attrs:{"index":_vm.zIndex,"entity-id":_vm.calendarEvent.entity_id ? _vm.calendarEvent.entity_id : NaN,"booking-view":false}}),_c('div',{staticClass:"tw-flex tw-font-extrabold",class:{
          'tw-mb-1': _vm.mode === _vm.CalendarDisplayType.PLANNING,
          'tw-mt-px': _vm.maxDisplayedLines <= 1,
          'tw-text-xs tw-leading-none tw-px-0.5 tw-py-0.5':
            _vm.maxDisplayedLines === 1,
          'tw-text-2xs tw-leading-none tw-px-1': _vm.maxDisplayedLines === 0
        }},[_c('div',{staticClass:"tw-flex-grow text-truncate",domProps:{"innerHTML":_vm._s(_vm.getSummary(_vm.calendarEvent))}}),(_vm.calendarEvent.comment && _vm.maxDisplayedLines > 1)?_c('div',[_c('i',{staticClass:"fas fa-comment",class:{
              'tw-text-white': _vm.lightText,
              'tw-text-2xs tw-relative tw--top-1.5 tw--right-0.5':
                _vm.mode === _vm.CalendarDisplayType.WEEK_GRID,
              'tw-text-xs': _vm.mode === _vm.CalendarDisplayType.DAY_GRID,
              'tw-text-lg': _vm.mode === _vm.CalendarDisplayType.PLANNING
            }})]):_vm._e()]),(
          (_vm.mode === _vm.CalendarDisplayType.DAY_GRID ||
            _vm.mode === _vm.CalendarDisplayType.WEEK_GRID) &&
            _vm.maxDisplayedLines >= 2
        )?_c('div',{staticClass:"tw-flex"},[_vm._v(" "+_vm._s(_vm._f("time")(_vm.calendarEvent.start))+" - "+_vm._s(_vm._f("time")(_vm.calendarEvent.end))+" ")]):_vm._e(),(_vm.mode === _vm.CalendarDisplayType.PLANNING)?_c('div',[_vm._l((_vm.calendarEvent.services_names),function(serviceName,serviceIndex){return _c('div',{key:serviceIndex,staticClass:"tw-flex tw-mt-2"},[_c('div',{staticClass:"tw-relative tw-overflow-visible tw-w-5 tw-mr-2 tw-text-center"},[_c('i',{staticClass:"fas fa-circle tw-text-3xs tw-mb-px tw-relative tw-bottom-0.5"}),(
                _vm.calendarEvent.services_names.length > 1 &&
                  serviceIndex < _vm.calendarEvent.services_names.length - 1
              )?_c('div',{staticClass:"tw-absolute tw-w-0.5 tw-bg-dark",class:{
                'tw-bg-white': _vm.lightText
              },staticStyle:{"top":"17px","right":"9px","height":"calc(100% - 6px)"}}):_vm._e()]),_c('div',{staticClass:"tw-flex-1"},[_vm._v(" "+_vm._s(serviceName)+" ")])])}),(_vm.calendarEvent.workplace_id && _vm.workplacesCount > 1)?_c('div',{staticClass:"tw-flex tw-mt-2"},[_vm._m(0),_c('div',[_vm._v(_vm._s(_vm.getWorkplaceById(_vm.calendarEvent.workplace_id).name))])]):_vm._e(),(_vm.calendarEvent.location_id && _vm.locationsCount > 1)?_c('div',{staticClass:"tw-flex tw-mt-2"},[_vm._m(1),_c('div',[_vm._v(" "+_vm._s(_vm.getLocationById(_vm.calendarEvent.location_id).name)+" ")])]):_vm._e()],2):_vm._e(),_c('calendar-event-modal',{ref:"eventModal",attrs:{"id":_vm.zIndex,"event":_vm.calendarEvent,"summary":_vm.getSummary(_vm.calendarEvent)}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-5 tw-mr-2"},[_c('i',{staticClass:"fas fa-door-open"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex-none tw-w-5 tw-mr-2 tw-text-center"},[_c('i',{staticClass:"fas fa-map-marker-alt"})])}]

export { render, staticRenderFns }