


















































import Search from '@/components/shared/Search.vue';
import BookingChoice from '@/components/bookings/BookingChoice.vue';
import union from 'lodash/union';
import Vue, { PropType } from 'vue';
import { Event } from '@/model/event';
import { Service } from '@/model/service';
import { useServicesStore } from '@/store/services-store';
import { mapStores } from 'pinia';

export default Vue.extend({
  components: {
    Search,
    BookingChoice
  },
  props: {
    locationId: {
      type: Number,
      default: null,
      required: false
    },
    employeeId: {
      type: Number,
      default: null,
      required: false
    },
    categoryId: {
      type: Number,
      default: null,
      required: false
    },
    selectedEvents: {
      type: Array as PropType<Event[]>,
      default: () => []
    },
    selectedServiceIds: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    singleSelection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchTerms: '',
      selectedServices: [] as Service[]
    };
  },
  computed: {
    ...mapStores(useServicesStore),
    servicesFiltered(): Service[] {
      let selectedEvents: number[] = this.selectedEvents;
      selectedEvents = selectedEvents.map((selectedBookingService: any) => {
        return selectedBookingService.service_id;
      });
      selectedEvents = union(selectedEvents, this.selectedServiceIds);
      // Services déjà sélectionnés par la réservation cachés (pas possible d'avoir 2x le même service sur une réservation)
      const servicesFiltered = this.servicesStore.entities.filter(service => {
        return (
          selectedEvents.indexOf(service.id) === -1 &&
          (!this.locationId ||
            service.workplaces.length === 0 ||
            service.workplaces.some(
              workplace => workplace.location_id === this.locationId
            )) &&
          (!this.employeeId ||
            service.employees.some(
              employee => employee.id === this.employeeId
            )) &&
          (!this.categoryId || service.category_id === this.categoryId)
        );
      });
      if (this.searchTerms) {
        return servicesFiltered.filter(service => {
          const splitVal = this.searchTerms.split(' ');
          const joinedSplitVal = splitVal.join('.*');
          const regex = new RegExp(joinedSplitVal, 'gi');
          return regex.test(`${service.no}${service.name}`);
        });
      }
      if (this.singleSelection) {
        return servicesFiltered.filter(
          service => !this.isServiceSelected(service)
        );
      } else {
        return servicesFiltered;
      }
    }
  },
  methods: {
    toggleService(selected: boolean, service: Service) {
      if (this.singleSelection) {
        this.selectedServices = [service];
        this.onSave();
        this.$bvModal.hide('select-services');
      } else {
        if (selected) {
          this.selectedServices.push(service);
        } else {
          this.selectedServices = this.selectedServices.filter(
            selectedService => {
              return selectedService.id !== service.id;
            }
          );
        }
      }
    },
    isServiceSelected(service: Service): boolean {
      return this.selectedServices.some(selectedService => {
        return selectedService.id === service.id;
      });
    },
    onSave() {
      this.$emit('addServices', this.selectedServices);
      this.selectedServices = [];
      this.searchTerms = '';
    }
  }
});
