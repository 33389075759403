






















































import TextInput from '@/components/shared/forms/TextInput.vue';
import moment from 'moment';
import { TIME_FORMAT } from '@/filters/time';
import { API_DATETIME_FORMAT } from '@/constants';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import TimeInput from '@/components/shared/forms/TimeInput.vue';
import DateInput from '@/components/shared/forms/DateInput.vue';
import Vue, { VueConstructor } from 'vue';
import { CalendarResourceType, useCalendarStore } from '@/store/calendar-store';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { Employee } from '@/model/employee';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    TextInput,
    TimeInput,
    DateInput
  },
  extends: CalendarBase,
  props: {
    validationErrors: {
      type: Object,
      required: true
    },
    defaultSummary: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      form: {
        date: '',
        summary: '',
        start: '',
        end: ''
      },
      VIEW_TYPE: CalendarResourceType
    };
  },
  computed: {
    ...mapStores(useCalendarStore, useEmployeesStore),
    selectedEmployee(): Employee {
      return this.employeesStore.currentEntity;
    },
    employee(): Employee {
      return this.employeesStore.getById(this.selectedEmployeeId);
    },
    dataModelForApi(): any {
      const start = moment(this.form.start, 'HH:mm');
      const end = moment(this.form.end, 'HH:mm');
      return {
        employee_id:
          this.viewType === CalendarResourceType.EMPLOYEE
            ? this.selectedEmployeeId
            : null,
        machine_id:
          this.viewType === CalendarResourceType.MACHINE
            ? this.selectedMachineId
            : null,
        workplace_id:
          this.viewType === CalendarResourceType.WORKPLACE
            ? this.selectedWorkplaceId
            : null,
        start: moment(this.form.date)
          .set({
            hour: start.get('hour'),
            minute: start.get('minute')
          })
          .format(API_DATETIME_FORMAT),
        end: moment(this.form.date)
          .set({
            hour: end.get('hour'),
            minute: end.get('minute')
          })
          .format(API_DATETIME_FORMAT),
        summary: this.form.summary
      };
    },
    endRules(): string {
      return `afterTime:${this.form.start}`;
    },
    startRules(): string {
      let rule = '';
      if (moment().isSame(this.form.date, 'day')) {
        rule += `afterTime:${moment().format(TIME_FORMAT)}`;
      }
      return rule;
    }
  },
  created() {
    this.form.date = this.date;
    this.form.summary = this.defaultSummary;
  }
});
