

















import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { LocaleMessages } from 'vue-i18n';

export interface ModalProps {
  okVariant?: 'primary' | 'danger';
  okTitle?: string | LocaleMessages;
  cancelTitle?: string | LocaleMessages;
}

export interface IConfirmModal {
  message: string | LocaleMessages;
  sendingData: boolean;
  modalProps: ModalProps;
  data: any;
  randomInt: number;
  openModal(
    message: string | LocaleMessages,
    modalProps: ModalProps,
    data?: any
  );
  closeModal(): void;
  onConfirm(bvModalEvt: { preventDefault: () => void }): void;
}

const DEFAULT_PROPS: ModalProps = {
  okTitle: 'Confirmer',
  okVariant: 'primary',
  cancelTitle: 'Annuler'
};

export default Vue.extend({
  data() {
    return {
      message: '' as string | LocaleMessages,
      sendingData: false,
      modalProps: cloneDeep(DEFAULT_PROPS) as ModalProps,
      data: null,
      randomInt: Math.ceil(Math.random() * 100000)
    };
  },
  methods: {
    /**
     * Ouvre une modal de confirmation
     */
    openModal(
      message: string | LocaleMessages,
      modalProps: ModalProps = {},
      data = null
    ) {
      this.message = message;
      this.data = data;
      Object.assign(this.modalProps, modalProps);
      this.$bvModal.show('confirm-modal-' + this.randomInt);
    },
    async onConfirm(bvModalEvt: { preventDefault: () => void }) {
      bvModalEvt.preventDefault();
      this.sendingData = true;
      this.$emit('confirm', this.data);
    },
    closeModal() {
      this.$bvModal.hide('confirm-modal-' + this.randomInt);
      this.message = '';
      this.modalProps = cloneDeep(DEFAULT_PROPS);
      this.data = null;
    }
  }
});
