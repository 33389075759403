import { defineStore } from 'pinia';
import {
  EntitiesState,
  getInitialEntityState
} from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Customer } from '@/model/customer';
import cloneDeep from 'lodash/cloneDeep';
import { Voucher } from '@/model/voucher';
import { Comment } from '@/model/comment';
import { Booking } from '@/model/booking';
import find from 'lodash/find';
import customerService from '@/services/customer.service';
import Vue from 'vue';
import { AlertType, useUiStore } from '@/store/ui-store';
import { BOOKING_STATUS } from '@/model/booking-status';
import bookingService from '@/services/booking.service';
import router from '@/routes';
import { CustomerBookingsStats } from '@/model/customer-bookings-stats';
import { CustomerPointsStats } from '@/model/customer-points-stats';

export enum BookingsFetchType {
  OPEN = 'open',
  CLOSED = 'closed'
}

const initialCustomersEntityState = {
  ...getInitialEntityState<Customer>()
};
const initialVouchersEntityState = getInitialEntityState<Voucher>();
const initialCommentsEntityState = getInitialEntityState<Comment>();
const initialOpenBookingsEntityState = getInitialEntityState<Booking>();
const initialClosedBookingsEntityState = getInitialEntityState<Booking>();

const state: EntitiesState<Customer> & {
  fidelityVouchers?: EntitiesState<Voucher> & { stats?: CustomerPointsStats };
  comments?: EntitiesState<Comment>;
  bookings?: {
    [BookingsFetchType.OPEN]: EntitiesState<Booking>;
    [BookingsFetchType.CLOSED]: EntitiesState<Booking> & {
      stats?: CustomerBookingsStats;
    };
  };
} = initialCustomersEntityState;
state.fidelityVouchers = initialVouchersEntityState;
state.fidelityVouchers.stats = {} as CustomerPointsStats;
state.comments = initialCommentsEntityState;
state.bookings = {
  [BookingsFetchType.OPEN]: initialOpenBookingsEntityState,
  [BookingsFetchType.CLOSED]: initialClosedBookingsEntityState
};
state.bookings[BookingsFetchType.CLOSED].stats = {} as CustomerBookingsStats;

/**
 * NOTE : si on passe aux entities actions pour les sous-modules ça ne fonctionnera pas, car le cloneDeep cassera le lien par référence
 * Pour le moment, entities state est uniquement utilisé pour les valeurs du state des sous-modules, pas les actions ni les getters
 * Par sous-module, on entend fidelityVouchers, bookings et comments
 */
const initialEntityStateClone = cloneDeep(state);

export const useCustomersStore = defineStore('customers', {
  state: () => state,
  getters: {
    ...createEntityGetters<Customer>()
  },
  actions: {
    ...createEntityActions<Customer>(
      Collection.CUSTOMERS,
      initialCustomersEntityState
    ),
    resetCustomer(): void {
      this.$patch({
        currentEntity: cloneDeep(initialEntityStateClone),
        fidelityVouchers: {
          ...cloneDeep(initialEntityStateClone),
          stats: {}
        },
        comments: {
          ...cloneDeep(initialEntityStateClone)
        },
        bookings: {
          [BookingsFetchType.OPEN]: {
            ...cloneDeep(initialEntityStateClone)
          },
          [BookingsFetchType.CLOSED]: {
            ...cloneDeep(initialEntityStateClone)
          }
        }
      });
    },
    _updateCurrentCustomer(customer: Customer): void {
      Vue.set(this, 'currentEntity', customer);
      this.fetching = false;
      this.failure = false;
    },
    async getAccount(
      userId: string | number,
      vendorId: string | number
    ): Promise<Customer | null> {
      const uiStore = useUiStore();
      this.fetching = true;
      if (
        this.currentEntity.user_id === parseInt(userId as string, 10) &&
        this.currentEntity.vendor_id === parseInt(vendorId as string, 10)
      ) {
        // Compte client courant
        this.fetching = false;

        return this.currentEntity;
      } else if (
        find(this.entities, { user_id: userId, vendor_id: vendorId })
      ) {
        // Compte client existant dans le state
        this.resetCustomer();
        const customer = find(this.entities, {
          user_id: userId,
          vendor_id: vendorId
        });
        this._updateCurrentCustomer(customer);

        return customer;
      } else {
        try {
          const customer = await customerService.getByUserIdAndVendorId(
            userId,
            vendorId
          );
          this.fetching = false;
          this._updateCurrentCustomer(customer);

          return customer;
        } catch (exception) {
          this.currentEntity = ({} as unknown) as Customer;
          this.fetching = false;
          this.failure = true;

          return null;
        }
      }
    },
    async getCustomer(id: number | string): Promise<Customer | null> {
      const uiStore = useUiStore();
      this.fetching = true;
      this.failure = false;
      if (this.currentEntity.id === parseInt(id as string, 10)) {
        // Compte client courant
        this.fetching = false;
        return this.currentEntity;
      } else if (find(this.entities, { id })) {
        // Compte client existant dans le state
        this.resetCustomer();
        const customer = find(this.entities, { id });
        this._updateCurrentCustomer(customer);
        return customer;
      } else {
        try {
          const customer = await customerService.getById(id);
          this._updateCurrentCustomer(customer);
          this.fetching = false;
          return customer;
        } catch (exception) {
          console.error(exception);
          this.fetching = false;
          this.failure = true;
          uiStore.alert(exception.message);
          return null;
        }
      }
    },
    async addForUser(vendorId: number) {
      const uiStore = useUiStore();
      try {
        this.saving = true;
        this.failure = false;
        const customerAdded = await customerService.addForUser(vendorId);
        this._updateCurrentCustomer(customerAdded);
        this.saving = false;

        return customerAdded.id;
      } catch (exception) {
        this.saving = false;
        this.failure = true;
        if (exception.errors !== undefined) {
          this.validationErrors = exception.errors;
        }
        uiStore.alert(
          exception.message,
          AlertType.DANGER,
          25,
          exception.errors
        );
      }
    },
    async getFidelityVouchers(customerId: number, page: number) {
      const uiStore = useUiStore();
      try {
        this.fidelityVouchers.failure = false;
        this.fidelityVouchers.fetching = true;
        const response = await customerService.getFidelityVouchersWithBalance(
          customerId,
          page
        );
        this.fidelityVouchers.entities = response.vouchers;
        this.fidelityVouchers.pagination = response.pagination;
        this.fidelityVouchers.fetching = false;
      } catch (exception) {
        this.fidelityVouchers.fetching = false;
        this.fidelityVouchers.failure = true;
        uiStore.alert(exception.message);
      }
    },
    async getPointsStatsForCustomer(customerId: number) {
      const uiStore = useUiStore();
      try {
        const stats = await customerService.getPointsStats(customerId);
        this.fidelityVouchers.stats = stats;
      } catch (exception) {
        uiStore.alert(exception.message);
      }
    },
    async getComments(customerId: number, page: number) {
      const uiStore = useUiStore();
      try {
        this.comments.fetching = true;
        const response = await customerService.getComments(customerId, page);
        this.comments.entities = response.comments;
        this.comments.pagination = response.pagination;
        this.comments.fetching = false;
      } catch (exception) {
        this.comments.fetching = false;
        this.comments.failure = true;
        uiStore.alert(exception.message);
      }
    },
    async addComment(customerId: number, comment: string) {
      const uiStore = useUiStore();
      this.comments.validationErrors = {};
      try {
        this.comments.saving = true;
        await customerService.addComment(customerId, comment);
        this.getComments(customerId, 1);
        this.comments.saving = false;

        return true;
      } catch (exception) {
        this.comments.saving = false;
        if (exception.errors !== undefined) {
          this.comments.validationErrors = exception.errors;
        }
        uiStore.alert(exception.message);

        return false;
      }
    },
    async editComment(
      commentId: number,
      comment: string,
      customerId: number,
      currentPage: number
    ) {
      const uiStore = useUiStore();
      try {
        this.comments.saving = true;
        await customerService.editComment(commentId, comment);
        this.comments.saving = false;
        this.getComments(customerId, currentPage);

        return true;
      } catch (exception) {
        this.comments.saving = false;
        uiStore.alert(exception.message);

        return false;
      }
    },
    async deleteComment(
      commentId: number,
      customerId: number,
      currentPage: number
    ) {
      const uiStore = useUiStore();
      try {
        this.comments.saving = true;
        await customerService.deleteComment(commentId);
        this.comments.saving = false;
        this.getComments(customerId, currentPage);

        return true;
      } catch (exception) {
        this.comments.saving = false;
        uiStore.alert(exception.message);

        return false;
      }
    },
    async getBookings(page: number, fetchType = BookingsFetchType.OPEN) {
      const uiStore = useUiStore();
      this.bookings[fetchType].fetching = true;
      this.bookings[fetchType].failure = false;
      try {
        const response = await customerService.getBookings(
          fetchType === BookingsFetchType.OPEN,
          this.currentEntity.id,
          page
        );
        this._setBookings(fetchType, response.bookings, response.pagination);
        this.bookings[fetchType].fetching = false;
        this.bookings[fetchType].failure = false;
      } catch (exception) {
        console.error(exception);
        uiStore.alert(exception.message);
        this.bookings[fetchType].fetching = false;
        this.bookings[fetchType].failure = true;
      }
    },
    _setBookings(
      fetchType: BookingsFetchType,
      bookings: Booking[],
      pagination?: any
    ) {
      this.bookings[fetchType].entities = bookings;
      if (pagination) {
        this.bookings[fetchType].pagination = pagination;
      }
    },
    async findBookingById(
      bookingId: number | string,
      customerId: number | string
    ): Promise<Booking> {
      const uiStore = useUiStore();
      try {
        const booking = await customerService.findBookingById(
          bookingId,
          customerId
        );
        this._setBookings(
          booking.status === BOOKING_STATUS.OPEN
            ? BookingsFetchType.OPEN
            : BookingsFetchType.CLOSED,
          [booking]
        );

        return booking;
      } catch (exception) {
        uiStore.alert(exception.message);
      }
    },
    async cancelBooking(bookingId: number) {
      const uiStore = useUiStore();
      try {
        const response = await customerService.cancelBooking(bookingId);
        if (response && response.message) {
          uiStore.alert(response.message, AlertType.SUCCESS);
        }

        return true;
      } catch (exception) {
        uiStore.alert(exception.message);

        return false;
      }
    },
    async closeBooking(
      fetchType: BookingsFetchType,
      bookingId: number,
      closeBookingDto: any,
      customerId: number
    ) {
      const uiStore = useUiStore();
      this.bookings[fetchType].saving = true;
      this.bookings[fetchType].validationErrors = {};
      try {
        const response = await bookingService.close(bookingId, closeBookingDto);
        this.bookings[fetchType].saving = false;
        uiStore.alert(response.message, AlertType.SUCCESS);
        router.push({
          name: 'CustomersView',
          params: { id: customerId.toString() }
        });
      } catch (exception) {
        this.bookings[fetchType].saving = false;
        if (exception.errors !== undefined) {
          this.bookings[fetchType].validationErrors = exception.errors;
        }
        uiStore.alertValidationError(exception);
      }
    },
    async getBookingsStatsForCustomer(customerId: number) {
      const uiStore = useUiStore();
      try {
        const stats = await bookingService.getStatsForCustomer(customerId);
        this.bookings[BookingsFetchType.CLOSED].stats = stats;

        return true;
      } catch (exception) {
        uiStore.alert(exception.message);

        return false;
      }
    }
  }
});
