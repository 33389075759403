












































import moment from 'moment';
import BaseEventMixin from '@/components/calendar/BaseEventMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import {
  CalendarResourceType,
  CalendarViewMode,
  useCalendarStore
} from '@/store/calendar-store';
import { useEmployeesStore } from '@/store/employees-store';
import { useMachinesStore } from '@/store/machines-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { CalendarEvent } from '@/components/calendar/models/calendar-event';
import { ColorHelper } from '@/helpers/color-helper';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import { Employee } from '@/model/employee';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseEventMixin> & InstanceType<typeof CalendarBase>
>).extend({
  extends: CalendarBase,
  mixins: [BaseEventMixin],
  props: {
    entityId: {
      type: Number,
      required: true
    },
    dateProp: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ColorHelper: ColorHelper
    };
  },
  computed: {
    ...mapStores(
      useCalendarStore,
      useEmployeesStore,
      useMachinesStore,
      useWorkplacesStore
    ),
    viewType(): CalendarResourceType {
      return this.calendarStore.viewType;
    },
    employee(): Employee | null {
      if (this.viewType === CalendarResourceType.EMPLOYEE) {
        return this.employeesStore.getById(this.entityId);
      } else {
        return null;
      }
    },
    calendarEvents(): CalendarEvent[] {
      return this.calendarStore.getCalendarEvents(this.entityId, this.dateProp);
    },
    blockWidthInMinutes(): number {
      return 60 * 24;
    }
  },
  methods: {
    getWorkplaceById(workplaceId: number) {
      return this.workplacesStore.getById(workplaceId);
    },
    getStartEndHumanized(calendarEvent: CalendarEvent) {
      return (
        moment(calendarEvent.start).format('HH:mm') +
        ' - ' +
        moment(calendarEvent.end).format('HH:mm')
      );
    },
    openDate() {
      this.calendarStore.selectDate(this.dateProp);
      this.calendarStore.setViewMode(CalendarViewMode.PERSONAL);
      switch (this.viewType) {
        case CalendarResourceType.EMPLOYEE:
          this.employeesStore.setCurrentEntity(
            this.employeesStore.getById(this.entityId)
          );
          break;
        case CalendarResourceType.MACHINE:
          this.machinesStore.setCurrentEntity(
            this.machinesStore.getById(this.entityId)
          );
          break;
        case CalendarResourceType.WORKPLACE:
          this.workplacesStore.setCurrentEntity(
            this.workplacesStore.getById(this.entityId)
          );
          break;
      }
    },
    getItemWidth(calendarEvent: Partial<CalendarEvent>): number {
      const eventDuration = moment(calendarEvent.end).diff(
        moment(calendarEvent.start),
        'minutes'
      );

      return (eventDuration / this.blockWidthInMinutes) * 100;
    },
    getItemLeftOffset(calendarEvent: Partial<CalendarEvent>): number {
      const diffInMinutesFromScaleStart = moment(calendarEvent.start).diff(
        moment(this.dateProp).startOf('day'),
        'minutes'
      );
      if (diffInMinutesFromScaleStart > 0) {
        return (diffInMinutesFromScaleStart / this.blockWidthInMinutes) * 100;
      } else {
        return 0;
      }
    }
  }
});
