import apiService from '@/services/api.service';

export default {
  async findAll(vendorId) {
    const response = await apiService.get(
      `/legal-conditions.json?vendor_id=${vendorId}`
    );
    return response.data.data;
  }
};
