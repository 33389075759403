var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content tw-flex tw-overflow-hidden",class:{
    'md:tw-h-screen-navbar xl:tw-h-screen': !_vm.mobileLandscape,
    'tw-h-screen': _vm.mobileLandscape
  }},[_c('div',{staticClass:"tw-flex-grow tw-flex tw-flex-col",class:{ 'lg:tw-mr-6': _vm.activeMode === _vm.CalendarViewMode.PERSONAL }},[_c('calendar-filters',{staticClass:"tw-flex-none tw-pb-4 lg:tw-pb-0"}),_c('div',{staticClass:"tw-flex-none tw-bg-white tw-rounded-t-xl tw-pb-1",class:{
        'tw-border-b tw-border-gray-100':
          _vm.mobileLandscape && _vm.activeMode === _vm.CalendarViewMode.PERSONAL
      }},[_c('div',{staticClass:"tw-flex-grow lg:tw-pl-3 lg:tw-pr-5",class:{
          'tw-flex': !_vm.mobileLandscape
        }},[_c('div',{staticClass:"tw-flex-none",class:{
            'tw-w-12 tw-mr-0.5':
              _vm.displayType === _vm.CalendarDisplayType.WEEK_GRID &&
              _vm.activeMode === _vm.CalendarViewMode.PERSONAL
          }}),_c('div',{staticClass:"tw-flex-grow"},[_c('div',{staticClass:"tw-flex-none tw-flex tw-justify-between tw-items-center"},[(_vm.mobileLandscape)?_c('div',{staticClass:"tw-flex-none tw-w-12 tw-mt-2 tw-font-bold tw-text-sm tw-pl-4"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.currentWeekDays[0].toDate(),'MMM YYYY'))+" ")]):_vm._e(),_c('calendar-widget')],1),(
              (_vm.displayType === _vm.CalendarDisplayType.PLANNING ||
                _vm.displayType === _vm.CalendarDisplayType.DAY_GRID ||
                _vm.forceShowSchedule) &&
                _vm.activeMode === _vm.VIEW_MODE.PERSONAL
            )?_c('calendar-schedule',{staticClass:"tw-flex-none tw-my-3 tw-mx-4",attrs:{"selected-date":_vm.date}}):_vm._e(),(
              !_vm.mobileLandscape &&
                _vm.resourceType === _vm.CalendarResourceType.EMPLOYEE &&
                _vm.activeMode === _vm.CalendarViewMode.PERSONAL &&
                _vm.displayType === _vm.CalendarDisplayType.WEEK_GRID
            )?_c('div',{staticClass:"tw-flex tw-gap-1 tw-ml-1"},_vm._l((_vm.currentWeekDays),function(day){return _c('div',{key:day.weekday(),staticClass:"tw-flex-1"},[_c('calendar-schedule',{attrs:{"compact":true,"selected-date":day.format(_vm.API_DATE_FORMAT)}})],1)}),0):_vm._e()],1)])]),_c('div',{staticClass:"tw-flex-grow tw-bg-white tw-rounded-b-xl md:tw-overflow-auto scrollbar-thin",class:{ 'tw-py-2 tw-overflow-auto': _vm.mobileLandscape }},[(_vm.activeMode === _vm.VIEW_MODE.PERSONAL)?_c('calendar-day-view'):_vm._e(),(_vm.activeMode === _vm.VIEW_MODE.TEAM)?_c('calendar-team-view'):_vm._e()],1)],1),(_vm.activeMode === _vm.VIEW_MODE.PERSONAL)?_c('div',{staticClass:"tw-hidden lg:tw-block lg:tw-flex-80"},[_c('div',{staticClass:"tw-bg-white tw-rounded-xl"},[_c('calendar-widget',{attrs:{"always-expanded":true}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }