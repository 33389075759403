



















import Vue from 'vue';
import VueSlider from 'vue-slider-component';
import { mapStores } from 'pinia';
import { useCalendarStore } from '@/store/calendar-store';

export default Vue.extend({
  components: { VueSlider },
  data() {
    return {
      value: 0
    };
  },
  computed: {
    ...mapStores(useCalendarStore)
  },
  created() {
    this.value = this.calendarStore.zoom;
  },
  methods: {
    valueChanged(zoomLevel: number) {
      this.calendarStore.setZoom(zoomLevel);
    }
  }
});
