var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-modal',{ref:"confirmModal",on:{"confirm":_vm.onDelete}}),_c('b-modal',{attrs:{"id":'event-modal-' + _vm.id,"title":_vm.$t('label.eventDetails'),"centered":true,"busy":_vm.sendingData,"ok-only":"","ok-title":_vm.isBooking ? _vm.$t('button.cancelBooking') : _vm.$t('button.delete'),"ok-variant":"secondary"},on:{"ok":_vm.showDeleteConfirmModal}},[_c('div',{staticClass:"tw-py-2.5 tw-px-4 tw-bg-gray-100 tw-rounded"},[_c('i',{staticClass:"fas fa-calendar mr-2"}),_vm._v(" "+_vm._s(_vm._f("weekday")(_vm.event.start))+" "+_vm._s(_vm._f("date")(_vm.event.start,'DD MMM YYYY'))+" - "+_vm._s(_vm._f("time")(_vm.event.start))+" à "+_vm._s(_vm._f("time")(_vm.event.end))+" ")]),(!_vm.event.booking_id)?_c('div',{staticClass:"tw-pt-2 tw-px-3"},[_c('i',{staticClass:"fas fa-calendar-star mr-2"}),_vm._v(" "+_vm._s(_vm.summary)+" ")]):_vm._e(),(_vm.isBooking)?_c('div',{staticClass:"tw-mt-2 tw-py-2.5 tw-px-4 tw-font-bold"},[_c('router-link',{attrs:{"to":{
          name: 'CustomersView',
          params: { id: _vm.event.customer.id }
        }}},[_c('i',{staticClass:"fas fa-user-circle mr-2"}),_vm._v(" "+_vm._s(_vm.event.customer.firstname)+" "+_vm._s(_vm.event.customer.lastname)+" ")])],1):_vm._e(),(_vm.isBooking)?_c('div',{staticClass:"tw-py-1.5 tw-px-4"},_vm._l((_vm.event.services_names),function(serviceName,serviceIndex){return _c('div',{key:serviceIndex,staticClass:"tw-flex tw-mb-2"},[_c('div',{staticClass:"tw-relative tw-overflow-visible tw-w-4 tw-mr-2 tw-text-center tw-flex tw-items-center tw-justify-center"},[_c('i',{staticClass:"fas fa-circle tw-text-3xs tw-mb-px"}),(serviceIndex > 0)?_c('div',{staticClass:"tw-absolute tw-h-5 tw-w-0.5 tw-left-1.75 tw-bg-dark tw--top-3.75"}):_vm._e()]),_c('div',{staticClass:"tw-truncate tw-ml-1.5"},[_vm._v(" "+_vm._s(serviceName)+" ")])])}),0):_vm._e(),(_vm.event.workplace_id)?_c('div',{staticClass:"tw-mt-2 tw-py-2.5 tw-px-4 tw-rounded tw-font-bold",class:[
        {
          'tw-text-light': !_vm.ColorHelper.isColorLight(
            _vm.getWorkplaceById(_vm.event.workplace_id).color,
            _vm.getWorkplaceById(_vm.event.workplace_id).hue
          )
        },
        ("tw-bg-" + (_vm.getWorkplaceById(_vm.event.workplace_id).color) + "-" + (_vm.getWorkplaceById(_vm.event.workplace_id).hue))
      ]},[_c('div',{staticClass:"tw-pb-2"},[_c('i',{staticClass:"fas fa-door-open tw-mr-2"}),_vm._v(" "+_vm._s(_vm.getWorkplaceById(_vm.event.workplace_id).name)+" ")]),_c('div',[_c('i',{staticClass:"fas fa-map-marker-alt tw-ml-1 tw-mr-2"}),_c('span',{staticClass:"tw-ml-2"},[_vm._v(_vm._s(_vm.getLocationById(_vm.event.location_id).name))])])]):_vm._e(),(_vm.event.comment)?_c('div',{staticClass:"tw-mt-2 tw-py-2.5 tw-px-4 tw-font-bold"},[_c('i',{staticClass:"fas fa-comment tw-mr-2"}),_vm._v(" "+_vm._s(_vm.$t('booking.stepComment'))+" "),_c('div',{staticClass:"tw-font-normal tw-mt-2"},[_vm._v(" "+_vm._s(_vm.event.comment)+" ")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }