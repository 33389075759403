var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'tw-flex tw-mx-4 tw-mb-4 tw-pb-4 md:tw-pb-0': _vm.calendarEvents.length > 0
  }},[(_vm.calendarEvents.length > 0)?_c('div',{staticClass:"tw-flex-grow"},_vm._l((_vm.calendarEvents),function(calendarEvent,index){return _c('div',{key:calendarEvent.start,staticClass:"tw-flex tw-mb-1"},[_c('div',{staticClass:"tw-relative tw-flex-none tw-w-14 tw-flex tw-flex-col tw-justify-between tw-items-center tw-text-center tw-my-2 tw-text-right tw-text-xs tw-font-bold"},[_c('div',{class:{ 'tw-text-primary-500': _vm.nowBarDuringEventIndex === index }},[_vm._v(" "+_vm._s(_vm._f("date")(calendarEvent.start,'HH:mm'))+" ")]),_c('div',{staticClass:"tw-flex-grow tw-bg-gray-100 tw-w-0.5 tw-my-1"}),(_vm.nowBarDuringEventIndex === index)?_c('div',{staticClass:"tw-absolute tw-left-1/2 tw-top-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-w-2.5 tw-h-2.5 tw-rounded-full tw-bg-primary-gradient"}):_vm._e(),_c('div',{class:{
            'tw-text-primary-400': _vm.nowBarDuringEventIndex === index,
            'tw-text-gray-400': _vm.nowBarDuringEventIndex !== index
          }},[_vm._v(" "+_vm._s(_vm._f("date")(calendarEvent.end,'HH:mm'))+" ")])]),_c('div',{staticClass:"tw-flex-grow tw-flex tw-flex-col tw-pt-0.5"},[(
            _vm.nowBarBeforeEventIndex === index ||
              (_vm.nowBarAfterAllEvents && index === _vm.calendarEvents.length - 1)
          )?_c('div',{staticClass:"tw-relative tw-bottom-0.5 tw-pl-2 tw-pr-1 py-1",class:{
            'tw-order-1': _vm.nowBarAfterAllEvents
          }},[_vm._m(0,true)]):_vm._e(),_c('event-card',{attrs:{"mode":_vm.CalendarDisplayType.PLANNING,"calendar-event":calendarEvent,"z-index":index}})],1)])}),0):_c('div',[_c('empty-dataset',{attrs:{"icon":"calendar-times","margin-top":false,"text":_vm.$t('emptyDataset.planning')}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-h-0.5 tw-bg-primary-gradient"},[_c('div',{staticClass:"tw-w-2.5 tw-h-2.5 tw-rounded-full tw-bg-primary-gradient tw-absolute tw--left-0.5 tw-top-0"})])}]

export { render, staticRenderFns }