





































































































import Vue, { VueConstructor } from 'vue';
import EventCard from '@/components/calendar/EventCard.vue';
import { API_DATE_FORMAT, API_DATETIME_FORMAT } from '@/constants';
import { mapStores } from 'pinia';
import {
  CalendarDisplayType,
  CalendarResourceType,
  useCalendarStore
} from '@/store/calendar-store';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import moment, { Moment } from 'moment';
import { CalendarEvent } from '@/components/calendar/models/calendar-event';
import { useUiStore } from '@/store/ui-store';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    EventCard
  },
  mixins: [CalendarBase],
  data() {
    return {
      CalendarDisplayType: CalendarDisplayType,
      nowBarTopOffset: null as string,
      API_DATE_FORMAT: API_DATE_FORMAT
    };
  },
  computed: {
    ...mapStores(useCalendarStore, useUiStore),
    hourGridBlockHeightInPx(): number {
      return 75 + this.calendarStore.zoom * 8;
    },
    calendarEvents(): CalendarEvent[] {
      if (this.displayType === CalendarDisplayType.DAY_GRID) {
        return this.calendarStore.getCalendarEvents(
          this.selectedEntityId,
          this.date
        );
      } else if (this.displayType === CalendarDisplayType.WEEK_GRID) {
        let calendarEvents: CalendarEvent[] = [];
        this.currentWeekDays.forEach(day => {
          calendarEvents = calendarEvents.concat(
            this.calendarStore.getCalendarEvents(
              this.selectedEntityId,
              day.format(API_DATE_FORMAT)
            )
          );
        });

        return calendarEvents;
      }

      return [];
    },
    gridBlocks(): Date[] {
      let time: Moment = moment(this.date, API_DATE_FORMAT).startOf('day');
      const gridBlocks: Date[] = [];
      while (
        time.isSameOrBefore(moment(this.date, API_DATE_FORMAT).endOf('day'))
      ) {
        gridBlocks.push(time.toDate());
        time = time.clone().add(1, 'hour');
      }

      gridBlocks.push(time.toDate());
      time = time.clone().add(1, 'hour');

      return gridBlocks;
    },
    nowBarVisible(): boolean {
      return (
        (this.displayType === CalendarDisplayType.WEEK_GRID &&
          moment(this.date).isSame(moment(), 'date')) ||
        (this.displayType === CalendarDisplayType.DAY_GRID &&
          moment(this.date).isSame(moment(), 'date'))
      );
    },
    nowBarLeftOffset(): string {
      return this.getItemLeftOffset(
        moment()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .format(API_DATETIME_FORMAT)
      );
    },
    resourceType(): CalendarResourceType {
      return this.calendarStore.viewType;
    }
  },
  watch: {
    resourceType() {
      this.scrollToStartOfDay();
    },
    displayType() {
      this.scrollToStartOfDay();
    }
  },
  mounted() {
    this.scrollToStartOfDay();
    this.updateNowBarTopOffset();
    setInterval(() => {
      this.updateNowBarTopOffset();
    }, 60 * 1000);
  },
  methods: {
    scrollToStartOfDay() {
      this.$refs.gridBlock[this.calendarStore.startOfDay].scrollIntoView();
    },
    updateNowBarTopOffset() {
      const diffInMinutesFromScaleStart: number = moment().diff(
        moment().startOf('day'),
        'minutes'
      );
      const diffInHoursFromScaleStart: number =
        diffInMinutesFromScaleStart / 60;

      this.nowBarTopOffset =
        diffInHoursFromScaleStart * this.hourGridBlockHeightInPx + 'px';
    },
    getDiffInMinutesFromDayStart(start: string | Moment): number {
      return moment(start).diff(
        moment(
          `${moment(start).format(API_DATE_FORMAT)}`,
          API_DATETIME_FORMAT
        ).startOf('day'),
        'minutes'
      );
    },
    getItemTopOffset(start: string | Moment): string {
      if (this.getDiffInMinutesFromDayStart(start) > 0) {
        return (
          (this.getDiffInMinutesFromDayStart(start) / 60) *
            this.hourGridBlockHeightInPx +
          1 +
          'px'
        );
      } else {
        return '0px';
      }
    },
    getItemHeight(start: string | Moment, end: string | Moment): number {
      return (
        (moment(end).diff(moment(start), 'minutes') / 60) *
        this.hourGridBlockHeightInPx
      );
    },
    getItemWidth(isEvent = true): string {
      if (this.displayType === CalendarDisplayType.WEEK_GRID) {
        const ratio = (1 / 7) * 100;
        if (isEvent) {
          return `calc(${ratio}% - 6px)`;
        } else {
          return `calc(${ratio}% - 14px)`;
        }
      } else {
        if (isEvent) {
          return 'calc(100% - 3.25rem + 2px)';
        } else {
          return 'calc(100% - 3.25rem - 4px)';
        }
      }
    },
    getItemLeftOffset(startTime: string | Moment): string {
      if (this.displayType === CalendarDisplayType.DAY_GRID) {
        return '3.25rem';
      } else {
        const ratio = (1 / 7) * 100;
        const weekday: number = moment(startTime).weekday();

        return `calc(${weekday * ratio}% + 3.25rem - ${weekday *
          (1 / 14) *
          100}px)`;
      }
    }
  }
});
