












































































































































































































import SelectInput from '@/components/shared/forms/SelectInput.vue';
import TextInput from '@/components/shared/forms/TextInput.vue';
import SelectServicesModal from '@/components/shared/SelectServicesModal.vue';
import BookingChoice from '@/components/bookings/BookingChoice.vue';
import bookingsService from '@/services/booking.service';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import * as Sentry from '@sentry/vue';
import Vue, { PropType } from 'vue';
import { Event } from '@/model/event';
import { Service } from '@/model/service';
import { InputOption } from '@/model/input-option';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { mapStores } from 'pinia';
import { useMachinesStore } from '@/store/machines-store';
import { useEmployeesStore } from '@/store/employees-store';
import { useUsersStore } from '@/store/users-store';
import { useBookingsStore } from '@/store/bookings-store';
import moment from 'moment';

export default Vue.extend({
  components: {
    LoadingIndicator,
    SelectInput,
    TextInput,
    SelectServicesModal,
    BookingChoice
  },
  props: {
    events: {
      type: Array as PropType<Event[]>,
      default: () => []
    },
    validationErrors: {
      type: Object,
      default: () => null
    },
    closingForm: {
      type: Boolean,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    locationId: {
      type: Number,
      default: null,
      required: false
    },
    employeeId: {
      type: Number,
      default: null,
      required: false
    },
    customerId: {
      type: Number,
      default: null,
      required: false
    }
  },
  data() {
    return {
      localEvents: [] as Partial<Event>[],
      childServices: [] as Service[],
      selectedChildServices: [] as Service[],
      loading: false
    };
  },
  computed: {
    ...mapStores(
      useWorkplacesStore,
      useMachinesStore,
      useEmployeesStore,
      useUsersStore
    ),
    vendorId(): number {
      return this.usersStore.loggedInVendorId as number;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    }
  },
  watch: {
    localEvents: {
      handler: function() {
        this.$emit('update', this.localEvents);
      },
      deep: true
    }
  },
  created() {
    this.localEvents = this.events;
    for (const event of this.localEvents) {
      // @ts-ignore
      if (!event.effective_duration || event.effective_duration === '') {
        // @ts-ignore
        event.effective_duration = moment(event.customer_end).diff(
          moment(event.customer_start),
          'minutes'
        );
      }
    }
  },
  methods: {
    employeesAsSelectOptions(
      serviceId: number,
      locationId: number | null = null
    ): InputOption[] {
      return this.employeesStore.getAllForServiceAndLocationAsSelectOptions(
        serviceId,
        locationId
      );
    },
    machinesAsSelectOptions(
      locationId: number | null = null,
      categoryId: number | null = null
    ): InputOption[] {
      return this.machinesStore.getMachinesAsSelectOptions(
        locationId,
        categoryId
      );
    },
    workplacesAsSelectOptions(
      locationId: number | null = null,
      workplaceIds: number[] | null = null
    ): InputOption[] {
      return this.workplacesStore.getWorkplacesAsSelectOptions(
        locationId,
        workplaceIds
      );
    },
    openAddEventsModal() {
      this.$bvModal.show('select-services');
    },
    async addEvents(servicesToAdd: Service[]) {
      this.childServices = [];
      for (const serviceToAdd of servicesToAdd) {
        if (
          serviceToAdd.child_services &&
          serviceToAdd.child_services.length > 0
        ) {
          for (const childService of serviceToAdd.child_services) {
            if (
              !servicesToAdd.find(service => service.id === childService.id) &&
              !this.childServices.find(
                service => service.id === childService.id
              ) &&
              !this.localEvents.find(
                localEvent => localEvent.service_id === childService.id
              )
            ) {
              this.childServices.push(childService);
            }
          }
        }
      }
      if (this.childServices.length > 0) {
        this.selectedChildServices = [];
        this.$bvModal.show('child-services-modal');
      }
      if (this.customerId) {
        this.loading = true;
        const variableDurationServices = servicesToAdd.filter(
          service => !service.fixed_duration
        );
        try {
          const servicesHistory = await Promise.all(
            variableDurationServices.map(service =>
              bookingsService.getServiceHistoryForCustomer(
                service.id,
                this.customerId
              )
            )
          );
          const bookingsStore = useBookingsStore();
          for (let i = 0; i < variableDurationServices.length; i++) {
            bookingsStore.setServiceHistory(
              variableDurationServices[i],
              servicesHistory[i]
            );
          }
        } catch (e) {
          console.error(e.message);
          Sentry.captureException(e);
        }
        this.loading = false;
      }
      this.localEvents = this.localEvents.concat(
        servicesToAdd.map((service: Service) => {
          const workplaces = this.workplacesAsSelectOptions(
            this.locationId,
            service.workplaces.map(workplace => workplace.id)
          );
          const machines = this.machinesAsSelectOptions(
            this.locationId,
            service.machine_model ? service.machine_model.id : null
          );
          return {
            effective_duration: service.duration,
            employee_id: this.employeeId,
            workplace_id: workplaces.length === 1 ? workplaces[0].value : null,
            machine_id:
              machines.length === 1 && !this.isFromLPittet
                ? machines[0].value
                : null,
            service_id: service.id,
            service: service,
            vendor_id: this.vendorId
          };
        })
      );
      this.validationErrors.events = [];
    },
    deleteEvent(index) {
      this.localEvents.splice(index, 1);
    },
    getValidationErrorsForEvent(index: number) {
      return this.validationErrors.events &&
        this.validationErrors.events[index] !== undefined
        ? this.validationErrors.events[index]
        : {};
    },
    isChildServiceSelected(service: Service) {
      return (
        this.selectedChildServices.find(
          selectedService => selectedService.id === service.id
        ) !== undefined
      );
    },
    toggleChildService(selected: boolean, service: Service) {
      if (selected) {
        this.selectedChildServices.push(service);
      } else {
        const index: number = this.selectedChildServices.findIndex(
          selectedService => selectedService.id === service.id
        );
        if (index) {
          this.selectedChildServices.splice(index, 1);
        }
      }
    },
    changeServiceDuration(increment: number, index: number) {
      this.localEvents[index].effective_duration = Math.max(
        5,
        parseInt(this.localEvents[index].effective_duration.toString(), 10) +
          increment
      );
      this.$emit('update', this.localEvents);
    }
  }
});
