import Vue from 'vue';
import { mapStores } from 'pinia';
import { useServicesStore } from '@/store/services-store';
import { Service } from '@/model/service';
import { CalendarEvent } from '@/components/calendar/models/calendar-event';

export default Vue.extend({
  computed: {
    ...mapStores(useServicesStore)
  },
  methods: {
    getServiceById(id: number): Service {
      return this.servicesStore.getById(id);
    },
    getSummary(calendarEvent: CalendarEvent): string {
      return calendarEvent.customer
        ? calendarEvent.customer.firstname +
            ' ' +
            calendarEvent.customer.lastname
        : calendarEvent.summary;
    }
  }
});
