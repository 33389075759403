

















































































import BookingFormServices from '@/components/calendar/BookingFormServices.vue';
import moment, { Moment } from 'moment';
import CustomerAutocompleteInput from '@/components/shared/forms/CustomerAutocompleteInput.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import { ValidationObserver } from 'vee-validate';
import sumBy from 'lodash/sumBy';
import TimeInput from '@/components/shared/forms/TimeInput.vue';
import DateInput from '@/components/shared/forms/DateInput.vue';
import Vue from 'vue';
import { useCalendarStore } from '@/store/calendar-store';
import { useLocationsStore } from '@/store/locations-store';
import { useEmployeesStore } from '@/store/employees-store';
import { mapStores } from 'pinia';
import { InputOption } from '@/model/input-option';
import { Customer } from '@/model/customer';
import { Event } from '@/model/event';
import { Schedule } from '@/model/schedule';
import { MomentHelper } from '@/helpers/moment.helper';
import { API_DATETIME_FORMAT } from '@/constants';
import { useUsersStore } from '@/store/users-store';
import { Vendor } from '@/model/vendor';
import { useVendorsStore } from '@/store/vendors-store';

export default Vue.extend({
  components: {
    BookingFormServices,
    CustomerAutocompleteInput,
    SelectInput,
    ValidationObserver,
    TimeInput,
    DateInput
  },
  props: {
    validationErrors: {
      type: Object,
      required: true
    },
    employeeId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      booking: {
        date: '',
        start: '',
        end: '',
        customer: null,
        events: []
      },
      totalDuration: null,
      bookingEndDisabled: true,
      selectedLocationId: null as number | null
    };
  },
  computed: {
    ...mapStores(
      useCalendarStore,
      useLocationsStore,
      useEmployeesStore,
      useUsersStore,
      useVendorsStore
    ),
    selectedDate(): string {
      return this.calendarStore.selectedDate;
    },
    locationsAsSelectOptions(): InputOption[] {
      return this.locationsStore.getLocationsAsSelectOptions;
    },
    vendor(): Vendor {
      return this.vendorsStore.getById(this.usersStore.loggedInVendorId);
    }
  },
  async created() {
    this.booking.date = this.selectedDate;
    const locationOptions = this.locationsStore.getLocationsAsSelectOptions;
    if (locationOptions.length === 1) {
      this.selectedLocationId = parseInt(locationOptions[0].value, 10);
    }
    await this.vendorsStore.findOne(this.usersStore.loggedInVendorId);
  },
  methods: {
    selectCustomer(customer: Customer) {
      this.booking.customer = customer;
    },
    onEventsUpdate(events: Event[]) {
      this.totalDuration = sumBy(events, 'effective_duration');
      Vue.set(this.booking, 'events', events);
      this.booking.events = this.booking.events.map(event => {
        return {
          ...event,
          location_id: this.selectedLocationId
        };
      });
      if (this.booking.start) {
        this.onStartChange();
      }
    },
    onStartChange() {
      const start: Moment = moment(
        this.booking.date + ' ' + this.booking.start,
        API_DATETIME_FORMAT
      );
      if (start.isValid()) {
        const schedule: Schedule | null = this.calendarStore.getSchedule(
          this.employeeId,
          this.selectedDate
        );
        if (schedule?.available) {
          const timeRange = schedule.timeRanges.find(timeRange => {
            return MomentHelper.checkIfTimeRangesOverlap(
              moment(timeRange.start),
              moment(timeRange.end),
              start,
              start.clone().add(5, 'minutes')
            );
          });
          if (timeRange) {
            this.selectedLocationId = timeRange.location.id;
          }
        }
      }
      if (this.booking.events.length > 0) {
        this.booking.end = start
          .clone()
          .add(
            this.totalDuration + this.vendor.client_exit_duration_in_minutes,
            'minutes'
          )
          .format('HH:mm');
      }
    },
    async onSubmit() {
      const formIsValid = await (this.$refs.form as any).validate();
      if (formIsValid) {
        this.$emit('validated', this.booking);
      }
    }
  }
});
