var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-rounded-xl",class:{
    'tw-bg-light tw-p-4 lg:tw-px-5': !_vm.compact,
    'tw-cursor-pointer':
      !_vm.compact && _vm.displayType !== _vm.CalendarDisplayType.WEEK_GRID,
    'tw-mt-2 tw-p-2 tw-border-2 tw-text-center tw-text-xs tw-font-bold tw-cursor-pointer hover:tw-bg-dark hover:tw-text-white': _vm.compact,
    'tw-border-gray-700': _vm.compact && _vm.schedule && _vm.schedule.available,
    'tw-border-gray-400': _vm.compact && (!_vm.schedule || !_vm.schedule.available)
  },on:{"click":function($event){_vm.compact
      ? _vm.toggleDetailedView(true)
      : _vm.displayType === _vm.CalendarDisplayType.WEEK_GRID
      ? null
      : _vm.toggleReduced()}}},[(_vm.loadingSchedules && !_vm.schedule)?_c('div',{staticClass:"tw-flex tw-justify-center"},[(!_vm.compact)?_c('loading-indicator',{attrs:{"inline":true}}):_vm._e()],1):_c('div',[(_vm.compact && _vm.schedule && _vm.schedule.available)?_c('div',_vm._l((_vm.schedule.timeRanges),function(timeRange){return _c('div',{key:timeRange.start},[_c('span',{class:[
            'tw-text-' + timeRange.location.color + '-500',
            _vm.locationsCount > 1 ? '' : 'tw-hidden'
          ]},[_c('i',{staticClass:"fas fa-map-marker-alt tw-mr-1"})]),_vm._v(_vm._s(_vm._f("time")(timeRange.start))+" - "+_vm._s(_vm._f("time")(timeRange.end))+" ")])}),0):_vm._e(),(_vm.compact && (!_vm.schedule || !_vm.schedule.available))?_c('div',{staticClass:"tw-text-gray-400"},[(_vm.schedule && _vm.schedule.notAvailableSummary)?_c('div',[_vm._v(" "+_vm._s(_vm.schedule.notAvailableSummary)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('schedule.notPlanned'))+" ")])]):_vm._e(),(!_vm.compact)?_c('div',[_c('div',{staticClass:"tw-relative tw-flex tw-items-center tw-justify-between",class:_vm.reduced ? 'tw-hidden' : ''},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-text-4xl tw-font-extrabold"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.date,'DD'))+" ")]),_c('div',{staticClass:"tw-text-medium tw-text-sm ml-2"},[_c('div',{staticClass:"tw-leading-none"},[_vm._v(_vm._s(_vm._f("date")(_vm.date,'dddd')))]),_c('div',{staticClass:"tw-leading-none tw-pt-0.5"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.date,'MMM YYYY'))+" ")])]),(_vm.schedule && (_vm.schedule.isException || _vm.schedule.flexible))?_c('div',{staticClass:"tw-ml-3"},[(_vm.schedule.isException)?_c('span',{staticClass:"tw-badge tw-badge-primary mr-2"},[_vm._v(_vm._s(_vm.$t('label.special')))]):_vm._e(),(_vm.schedule.flexible)?_c('span',{staticClass:"tw-badge tw-badge-green"},[_vm._v(_vm._s(_vm.$t('label.flexible')))]):_vm._e()]):_vm._e()]),_c('div',[(
              _vm.forceShowSchedule &&
                _vm.displayType === _vm.CalendarDisplayType.WEEK_GRID
            )?_c('div',{staticClass:"tw-absolute tw--top-7 tw--right-7 tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-dark tw-text-white tw-cursor-pointer",on:{"click":function($event){return _vm.toggleDetailedView(false)}}},[_c('i',{staticClass:"far fa-times"})]):_vm._e(),(_vm.canSetTimetable)?_c('a',{staticClass:"tw-btn-link tw-text-dark",on:{"click":function($event){$event.stopPropagation();return _vm.openCreateTimetableExceptionModal()}}},[_c('i',{staticClass:"fas fa-pen tw-mr-0 lg:tw-mr-2"}),_c('span',{staticClass:"tw-hidden lg:tw-inline"},[_vm._v(_vm._s(_vm.$t('button.modify')))])]):_vm._e()])]),(_vm.schedule)?_c('div',{class:_vm.reduced ? '' : 'lg:tw-mt-3'},[_c('calendar-schedule-day-view',{attrs:{"weekday":_vm.schedule,"display-weekday":false,"calendar-mode":true}}),(!_vm.schedule.available && !_vm.loadingSchedules)?_c('div',{staticClass:"tw-text-sm ",class:_vm.reduced ? '' : 'tw-mt-2 tw-pt-3 tw-border-t tw-border-gray-200'},[(_vm.schedule.notAvailableSummary)?_c('span',[_vm._v(_vm._s(_vm.schedule.notAvailableSummary))]):_c('span',[_vm._v(_vm._s(_vm.$t('schedule.notPlanned')))])]):_vm._e()],1):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }