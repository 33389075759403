











































import CalendarTeamDayBlock from '@/components/calendar/CalendarTeamDayBlock.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import moment, { Moment } from 'moment';
import Vue, { VueConstructor } from 'vue';
import { CalendarResourceType, useCalendarStore } from '@/store/calendar-store';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useMachinesStore } from '@/store/machines-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useUsersStore } from '@/store/users-store';
import { Employee } from '@/model/employee';
import { Machine } from '@/model/machine';
import { Workplace } from '@/model/workplace';
import { API_DATE_FORMAT } from '@/constants';
import CalendarBase from '@/components/shared/CalendarBase.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    CalendarTeamDayBlock,
    LoadingIndicator
  },
  extends: CalendarBase,
  computed: {
    ...mapStores(
      useEmployeesStore,
      useCalendarStore,
      useMachinesStore,
      useWorkplacesStore,
      useUsersStore
    ),
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    selectedDate(): string {
      return this.calendarStore.selectedDate;
    },
    selectedEmployeeId(): number {
      return this.employeesStore.currentEntity.id;
    },
    viewType(): CalendarResourceType {
      return this.calendarStore.viewType;
    },
    machines(): Machine[] {
      return this.machinesStore.entities;
    },
    workplaces(): Workplace[] {
      return this.workplacesStore.entities;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    },
    currentWeekDates(): Moment[] {
      let currentDate = moment(this.selectedDate).startOf('week');
      const dates = [];
      for (let i = 0; i < 7; i++) {
        dates.push(currentDate);
        currentDate = currentDate.clone().add(1, 'day');
      }

      return dates;
    },
    loading(): boolean {
      return (
        this.calendarStore.schedules.fetching ||
        this.calendarStore.events.fetching
      );
    },
    entities(): Array<Employee | Machine | Workplace> {
      switch (this.viewType) {
        case CalendarResourceType.EMPLOYEE:
          return this.employees;
        case CalendarResourceType.MACHINE:
          return this.machines;
        case CalendarResourceType.WORKPLACE:
          return this.workplaces;
        default:
          return [];
      }
    }
  },
  created() {
    if (this.employees.length === 0) {
      const unsubscribe = this.employeesStore.$onAction(({ name, after }) => {
        if (name === 'findAll') {
          after(() => {
            unsubscribe();
            this.fetchEventsAndSchedules();
          });
        }
      });
    } else {
      this.fetchEventsAndSchedules();
    }
    this.calendarStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'selectDate' && this.employees.length > 0) {
          this.fetchEventsAndSchedules();
        } else if (name === 'setViewType') {
          this.fetchEventsAndSchedules();
        }
      });
    });
  },
  methods: {
    fetchEventsAndSchedules() {
      this.calendarStore.findSchedulesBetweenDates({
        dateFrom: this.currentWeekDates[0].format(API_DATE_FORMAT),
        dateTo: this.currentWeekDates[this.currentWeekDates.length - 1].format(
          API_DATE_FORMAT
        ),
        resourcesIds: this.entities.map(entity => entity.id)
      });
      this.calendarStore.findEventsBetweenDates({
        dateFrom: this.currentWeekDates[0].format(API_DATE_FORMAT),
        dateTo: this.currentWeekDates[this.currentWeekDates.length - 1].format(
          API_DATE_FORMAT
        )
      });
    },

    entityName(entity: Machine | Workplace | Employee): string {
      if (this.viewType === CalendarResourceType.EMPLOYEE) {
        return `${(entity as Employee).firstname} ${
          (entity as Employee).lastname
        }`;
      } else {
        return (entity as Machine | Workplace).name;
      }
    }
  }
});
