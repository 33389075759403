





















































































































import CalendarWidget from '@/components/calendar/CalendarWidget.vue';
import CalendarDayView from '@/components/calendar/CalendarDayView.vue';
import CalendarTeamView from '@/components/calendar/CalendarTeamView.vue';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import Vue, { VueConstructor } from 'vue';
import {
  CalendarViewMode,
  CalendarResourceType,
  useCalendarStore,
  CalendarDisplayType
} from '@/store/calendar-store';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useMachinesStore } from '@/store/machines-store';
import { InputOption } from '@/model/input-option';
import { Employee } from '@/model/employee';
import { Machine } from '@/model/machine';
import { Workplace } from '@/model/workplace';
import { useUiStore } from '@/store/ui-store';
import { API_DATE_FORMAT } from '@/constants';
import CalendarSchedule from '@/components/calendar/CalendarSchedule.vue';
import CalendarFilters from '@/components/calendar/CalendarFilters.vue';
import { useUsersStore } from '@/store/users-store';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    CalendarWidget,
    CalendarDayView,
    CalendarTeamView,
    CalendarSchedule,
    CalendarFilters
  },
  extends: CalendarBase,
  data() {
    return {
      API_DATE_FORMAT: API_DATE_FORMAT,
      VIEW_MODE: CalendarViewMode,
      CalendarResourceType: CalendarResourceType,
      CalendarDisplayType: CalendarDisplayType,
      resourceTypeHumanized: {
        [CalendarResourceType.EMPLOYEE]: this.$t('sidebar.employees'),
        [CalendarResourceType.WORKPLACE]: this.$tc('sidebar.workplaces', 2),
        [CalendarResourceType.MACHINE]: this.$tc('sidebar.resources', 2)
      },
      message: '',
      unsubscribeEmployeesStore: null,
      unsubscribeWorkplacesStore: null,
      unsubscribeMachinesStore: null,
      unsubscribeCalendarStore: null
    };
  },
  computed: {
    ...mapStores(
      useUsersStore,
      useCalendarStore,
      useEmployeesStore,
      useWorkplacesStore,
      useMachinesStore,
      useUiStore
    ),
    vendorId(): number {
      return this.usersStore.loggedInVendorId;
    },
    resourceType(): CalendarResourceType {
      return this.calendarStore.viewType;
    },
    forceShowSchedule(): boolean {
      return this.calendarStore.forceShowSchedule;
    },
    employees(): Employee[] {
      return this.employeesStore.entities;
    },
    machine(): Machine {
      return this.machinesStore.getById(this.selectedMachineId);
    },
    workplace(): Workplace {
      return this.workplacesStore.getById(this.selectedWorkplaceId);
    },
    canAddEventsOutOfSchedule(): boolean {
      return (
        this.resourceType !== CalendarResourceType.EMPLOYEE ||
        (this.employeesStore.getById(this.selectedEntityId) &&
          this.employeesStore.getById(this.selectedEntityId)
            .events_out_of_schedule)
      );
    },
    screenOrientation(): 'portrait' | 'landscape' {
      return this.uiStore.screenOrientation;
    },
    screenWidth(): number {
      return this.uiStore.screenWidth;
    },
    mobileLandscape(): boolean {
      return this.uiStore.mobileLandscape;
    }
  },
  watch: {
    $route() {
      this.calendarStore.setViewType(
        this.$route.query.viewType as CalendarResourceType
      );
    }
  },
  mounted() {
    this.uiStore.socket.on('events', message => {
      if (
        message.vendorId === this.vendorId &&
        this.employeesStore.currentEntity?.id === message.employeeId
      ) {
        this.fetchSchedule();
        this.fetchEvents();
      }
    });
    this.calendarStore.setViewType(
      this.$route.query.viewType as CalendarResourceType
    );
    this.subscribeToChangesAndUpdateUI(); // Il faut attendre que currentEntity.i
    this.handleScreenOrientationChange(); // Mettra en week par défaut pour tous les desktop + tablette
    this.uiStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setScreenOrientation') {
          this.handleScreenOrientationChange();
        }
      });
    });
    if (
      this.employeesStore.currentEntity?.id &&
      this.calendarStore.viewType === CalendarResourceType.EMPLOYEE
    ) {
      this.fetchSchedule();
      this.fetchEvents();
    }
  },
  destroyed() {
    this.unsubscribeEmployeesStore();
    this.unsubscribeWorkplacesStore();
    this.unsubscribeMachinesStore();
    this.unsubscribeCalendarStore();
  },
  methods: {
    handleScreenOrientationChange() {
      if (this.uiStore.mobileLandscape) {
        this.calendarStore.setDisplayType(CalendarDisplayType.WEEK_GRID);
      } else if (
        this.displayType === CalendarDisplayType.WEEK_GRID &&
        this.uiStore.screenOrientation === 'portrait'
      ) {
        const displayType = this.calendarStore.previousDisplayType
          ? this.calendarStore.previousDisplayType
          : CalendarDisplayType.DAY_GRID;
        this.calendarStore.setDisplayType(displayType);
      }
    },
    subscribeToChangesAndUpdateUI() {
      this.unsubscribeEmployeesStore = this.employeesStore.$onAction(
        this.refreshData
      );
      this.unsubscribeWorkplacesStore = this.workplacesStore.$onAction(
        this.refreshData
      );
      this.unsubscribeMachinesStore = this.machinesStore.$onAction(
        this.refreshData
      );
      this.unsubscribeCalendarStore = this.calendarStore.$onAction(
        this.refreshData
      );
    },
    refreshData({ name, after, store, args }) {
      after(() => {
        if (
          name === 'setCurrentEntity' ||
          name === 'setMonthYear' ||
          (store.$id === 'calendar' &&
            args[0] === CalendarResourceType.EMPLOYEE) ||
          (this.uiStore.socket.disconnected &&
            (name === 'addEvent' || name === 'deleteEvent') &&
            this.resourceType === CalendarResourceType.EMPLOYEE)
        ) {
          this.fetchSchedule();
          this.fetchEvents();
        } else if (args[0] === CalendarResourceType.MACHINE) {
          if (this.machine) {
            this.selectMachine(this.machine.id);
          } else if (this.machinesAsSelectOptions().length > 0) {
            this.selectMachine(this.machinesAsSelectOptions()[0].value);
          }
        } else if (args[0] === CalendarResourceType.WORKPLACE) {
          if (this.workplace) {
            this.selectWorkplace(this.workplace.id);
          } else if (this.machinesAsSelectOptions().length > 0) {
            this.selectWorkplace(this.workplacesAsSelectOptions()[0].value);
          }
        }
      });
    },
    selectEmployee(employeeId: number) {
      this.employeesStore.setCurrentEntity(
        this.employeesStore.getById(employeeId)
      );
    },
    selectWorkplace(workplaceId: number) {
      this.workplacesStore.setCurrentEntity(
        this.workplacesStore.getById(workplaceId)
      );
    },
    selectMachine(machineId: number) {
      this.machinesStore.setCurrentEntity(
        this.machinesStore.getById(machineId)
      );
    },
    workplacesAsSelectOptions(): InputOption[] {
      return this.workplacesStore.getWorkplacesAsSelectOptions();
    },
    machinesAsSelectOptions(): InputOption[] {
      return this.machinesStore.getMachinesAsSelectOptions();
    }
  }
});
