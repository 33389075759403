





























































































































import Vue, { VueConstructor } from 'vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import { CalendarResourceType, useCalendarStore } from '@/store/calendar-store';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import { mapStores } from 'pinia';
import { Schedule } from '@/model/schedule';
import CalendarScheduleDayView from '@/components/calendar/CalendarScheduleDayView.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    LoadingIndicator,
    CalendarScheduleDayView
  },
  mixins: [CalendarBase],
  props: {
    selectedDate: {
      type: String,
      required: true
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CalendarViewType: CalendarResourceType
    };
  },
  computed: {
    ...mapStores(useCalendarStore),
    loadingSchedules(): boolean {
      return this.calendarStore.schedules.fetching;
    },
    schedule(): Schedule | null {
      return this.calendarStore.getSchedule(
        this.selectedEntityId,
        this.selectedDate
      );
    },
    canSetTimetable(): boolean {
      return (
        (this.employeesStore.getById(this.selectedEntityId) &&
          this.employeesStore.getById(this.selectedEntityId)
            .can_set_timetable) ||
        this.isAdmin
      );
    },
    forceShowSchedule(): boolean {
      return this.calendarStore.forceShowSchedule;
    },
    reduced(): boolean {
      return this.calendarStore.reduced;
    }
  },
  methods: {
    openCreateTimetableExceptionModal() {
      this.$bvModal.show('create-timetable-exception-modal');
    },
    toggleDetailedView(forceShowSchedule: boolean) {
      this.calendarStore.selectDate(this.schedule.date);
      this.calendarStore.$patch({
        forceShowSchedule
      });
    },
    toggleReduced() {
      this.calendarStore.$patch({
        reduced: !this.reduced
      });
    }
  }
});
