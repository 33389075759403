































































import Vue, { VueConstructor } from 'vue';
import CalendarZoom from '@/components/calendar/CalendarZoom.vue';
import CalendarStartOfDay from '@/components/calendar/CalendarStartOfDay.vue';
import CalendarBase from '@/components/shared/CalendarBase.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    CalendarZoom,
    CalendarStartOfDay
  },
  extends: CalendarBase
});
