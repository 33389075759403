
















import CustomerForm from './CustomerForm.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCustomersStore } from '@/store/customers-store';

export default Vue.extend({
  components: {
    CustomerForm
  },
  props: {
    redirectOnSuccess: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapStores(useCustomersStore),
    validationErrors(): {} {
      return this.customersStore.validationErrors;
    },
    saving(): boolean {
      return this.customersStore.saving;
    }
  },
  methods: {
    async addCustomer(bvModalEvt) {
      bvModalEvt.preventDefault();
      const customer = await this.customersStore.create(
        (this.$refs as any).form.dataModelForApi
      );

      if (customer) {
        this.$bvModal.hide('customer-add-modal');
        if (this.redirectOnSuccess) {
          await this.$router.push({
            name: 'CustomersView',
            params: { id: (customer.id as unknown) as string }
          });
        } else {
          this.$emit('afterCustomerAdd', customer.id);
        }
      }
    }
  }
});
