


import {
  CalendarDisplayType,
  CalendarResourceType,
  CalendarSelectedMonth,
  CalendarViewMode,
  useCalendarStore
} from '@/store/calendar-store';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useMachinesStore } from '@/store/machines-store';
import moment, { Moment } from 'moment';
import { API_DATE_FORMAT, Breakpoint } from '@/constants';
import { Schedule } from '@/model/schedule';
import { Event } from '@/model/event';
import { Employee } from '@/model/employee';
import { useUiStore } from '@/store/ui-store';
import Hammer from 'hammerjs';
import { useLocationsStore } from '@/store/locations-store';

export default Vue.extend({
  data() {
    return {
      CalendarDisplayType: CalendarDisplayType,
      CalendarViewMode: CalendarViewMode,
      Breakpoint: Breakpoint
    };
  },
  computed: {
    ...mapStores(
      useCalendarStore,
      useEmployeesStore,
      useWorkplacesStore,
      useMachinesStore,
      useLocationsStore,
      useUiStore
    ),
    screenOrientation(): 'portrait' | 'landscape' {
      return this.uiStore.screenOrientation;
    },
    screenWidth(): number {
      return this.uiStore.screenWidth;
    },
    mobileLandscape(): boolean {
      return this.uiStore.mobileLandscape;
    },
    date(): string {
      return this.calendarStore.selectedDate;
    },
    activeMode(): CalendarViewMode {
      return this.calendarStore.viewMode;
    },
    viewType(): CalendarResourceType {
      return this.calendarStore.viewType;
    },
    displayType(): CalendarDisplayType {
      return this.calendarStore.displayType;
    },
    locationsCount(): number {
      return this.locationsStore.entities.length;
    },
    selectedEmployeeId(): number {
      return this.employeesStore.currentEntity?.id;
    },
    selectedEmployee(): Employee | null {
      return this.employeesStore.currentEntity;
    },
    selectedWorkplaceId(): number {
      return this.workplacesStore.currentEntity?.id;
    },
    selectedMachineId(): number {
      return this.machinesStore.currentEntity?.id;
    },
    selectedEntityId(): number | null {
      switch (this.viewType) {
        case CalendarResourceType.EMPLOYEE:
          return this.selectedEmployeeId;
        case CalendarResourceType.WORKPLACE:
          return this.selectedWorkplaceId;
        case CalendarResourceType.MACHINE:
          return this.selectedMachineId;
        default:
          return null;
      }
    },
    schedule(): Schedule | null {
      return this.calendarStore.getSchedule(this.selectedEntityId, this.date);
    },
    selectedMonth(): CalendarSelectedMonth {
      return this.calendarStore.selectedMonth;
    },
    dateFrom(): string {
      return moment()
        .month(this.selectedMonth.monthIndex)
        .year(this.selectedMonth.year)
        .startOf('month')
        .startOf('week')
        .format(API_DATE_FORMAT);
    },
    dateTo(): string {
      return moment()
        .month(this.selectedMonth.monthIndex)
        .year(this.selectedMonth.year)
        .endOf('month')
        .endOf('week')
        .format(API_DATE_FORMAT);
    },
    currentWeekDays(): Moment[] {
      if (
        this.displayType === CalendarDisplayType.DAY_GRID &&
        this.activeMode === CalendarViewMode.PERSONAL
      ) {
        return [moment(this.date)];
      } else {
        const dates: Moment[] = [];
        const day = moment(this.date).startOf('week');
        const lastDayOfWeek = moment(this.date).endOf('week');
        while (day.isSameOrBefore(lastDayOfWeek)) {
          dates.push(day.clone());
          day.add(1, 'day');
        }

        return dates;
      }
    }
  },
  methods: {
    changeViewMode(mode: CalendarViewMode) {
      this.calendarStore.setViewMode(mode);
    },
    changeDisplayType(displayType: CalendarDisplayType) {
      this.calendarStore.setDisplayType(displayType);
    },
    onSwipe(event) {
      if (event.direction === Hammer.DIRECTION_LEFT) {
        if (this.displayType === CalendarDisplayType.WEEK_GRID) {
          this.calendarStore.changeDayOrWeek(1, 'week');
        } else if (this.displayType === CalendarDisplayType.DAY_GRID) {
          this.calendarStore.changeDayOrWeek(1, 'day');
        }
      } else if (event.direction === Hammer.DIRECTION_RIGHT) {
        if (this.displayType === CalendarDisplayType.WEEK_GRID) {
          this.calendarStore.changeDayOrWeek(-1, 'week');
        } else if (this.displayType === CalendarDisplayType.DAY_GRID) {
          this.calendarStore.changeDayOrWeek(-1, 'day');
        }
      }
    },
    getScheduleForDate(date: string): Schedule | null {
      return this.calendarStore.getSchedule(this.selectedEntityId, date);
    },
    getEventsForDate(date: string): Event[] {
      return this.calendarStore.getEvents(this.selectedEntityId, date);
    },
    fetchSchedule() {
      this.calendarStore.findSchedulesBetweenDates({
        employeeId:
          this.viewType === CalendarResourceType.EMPLOYEE
            ? this.selectedEmployeeId
            : null,
        workplaceId:
          this.viewType === CalendarResourceType.WORKPLACE
            ? this.selectedWorkplaceId
            : null,
        machineId:
          this.viewType === CalendarResourceType.MACHINE
            ? this.selectedMachineId
            : null,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      });
    },
    fetchEvents() {
      this.calendarStore.findEventsBetweenDates({
        employeeId:
          this.viewType === CalendarResourceType.EMPLOYEE
            ? this.selectedEmployeeId
            : null,
        workplaceId:
          this.viewType === CalendarResourceType.WORKPLACE
            ? this.selectedWorkplaceId
            : null,
        machineId:
          this.viewType === CalendarResourceType.MACHINE
            ? this.selectedMachineId
            : null,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      });
    },
    getUnavailableTimeSlots(
      date: string,
      employee: Employee
    ): { start: Moment; end: Moment }[] {
      if (
        this.viewType === CalendarResourceType.EMPLOYEE &&
        (this.activeMode === CalendarViewMode.TEAM ||
          (!employee?.events_out_of_schedule &&
            this.activeMode === CalendarViewMode.PERSONAL))
      ) {
        let unavailableSlots: { start: Moment; end: Moment }[] = [];
        const schedule: Schedule | null = this.calendarStore.getSchedule(
          employee.id,
          date
        );
        const startOfDay: Moment = moment(date).startOf('day');
        if (schedule?.available) {
          // Début
          unavailableSlots.push({
            start: startOfDay,
            end: moment(schedule.timeRanges[0].start)
          });
          // Entre time ranges
          for (
            let timeRangeIndex = 0;
            timeRangeIndex < schedule.timeRanges.length - 1;
            timeRangeIndex++
          ) {
            if (
              moment(schedule.timeRanges[timeRangeIndex].end).isBefore(
                schedule.timeRanges[timeRangeIndex + 1].start
              )
            ) {
              // Possible que des créneaux se chevauchent en mode flexible, d'où cette condition
              unavailableSlots.push({
                start: moment(schedule.timeRanges[timeRangeIndex].end),
                end: moment(schedule.timeRanges[timeRangeIndex + 1].start)
              });
            }
          }
          // Fin
          unavailableSlots.push({
            start: moment(
              schedule.timeRanges[schedule.timeRanges.length - 1].end
            ),
            end: moment(date).endOf('day')
          });
        } else {
          unavailableSlots.push({
            start: startOfDay,
            end: moment(date).endOf('day')
          });
        }

        unavailableSlots = unavailableSlots.filter(unavailableSlot => {
          return !unavailableSlot.start.isSame(unavailableSlot.end);
        });

        return unavailableSlots;
      } else {
        return [];
      }
    }
  }
});
