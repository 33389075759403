var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-group mb-0"},[_c('label',[_vm._v(_vm._s(_vm.$t('sidebar.services')))])]),_vm._l((_vm.localEvents),function(event,index){return _c('div',{key:index,class:{ 'mt-3': index > 0 }},[_c('div',{staticClass:"service-container d-md-flex mb-4"},[_c('div',{staticClass:"flex-md-grow-1 bg-white border-radius px-3 pt-3 mr-md-3 mb-3 mb-md-0"},[_c('div',{staticClass:"d-flex justify-content-between align-items-start"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(event.service.no)+". "+_vm._s(event.service.name)+" ")]),(!_vm.closingForm)?_c('div',{staticClass:"d-flex align-items-start"},[(!event.service.fixed_duration)?_c('div',{staticClass:"btn btn-link px-1 pt-0",on:{"click":function($event){return _vm.changeServiceDuration(-5, index)}}},[_c('i',{staticClass:"fas fa-minus mr-0"})]):_vm._e(),(!event.service.fixed_duration)?_c('div',{staticClass:"btn btn-link pl-1 pt-0 pr-1",on:{"click":function($event){return _vm.changeServiceDuration(5, index)}}},[_c('i',{staticClass:"fas fa-plus mr-0"})]):_vm._e(),_c('div',[_vm._v(_vm._s(event.effective_duration)+"min")])]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.closingForm)?_c('div',{staticClass:"mt-3 col-md-4"},[_c('text-input',{attrs:{"id":'service-' + event.id,"value":event.effective_duration ? event.effective_duration + '' : '',"errors":_vm.getValidationErrorsForEvent(index),"disabled":event.service.fixed_duration,"name":"effective_duration","name-prefix":event.id + '_',"label":event.service.fixed_duration
                  ? _vm.$t('label.fixedDuration')
                  : _vm.$t('label.effectiveDuration'),"rules":"required","type":"text","append-text":"mn"},on:{"onValueChange":function (value) {
                  event.effective_duration = value;
                }}})],1):_vm._e(),(_vm.closingForm)?_c('div',{staticClass:"mt-3 col-md-4"},[_c('select-input',{attrs:{"options":_vm.employeesAsSelectOptions(event.service_id),"value":event.employee_id,"name":"employee_id","name-prefix":event.id + '_',"rules":"required","errors":_vm.getValidationErrorsForEvent(index),"label":_vm.$t('vendorSignUp.generalSettings.employee')},on:{"onValueChange":function (value) {
                  event.employee_id = value;
                }}})],1):_vm._e(),(
              event.service.workplaces && event.service.workplaces.length > 0
            )?_c('div',{staticClass:"mt-3",class:{ 'col-md-4': _vm.closingForm, 'col-12': !_vm.closingForm }},[_c('select-input',{attrs:{"inline":_vm.closingForm,"options":_vm.workplacesAsSelectOptions(
                  _vm.locationId,
                  event.service.workplaces.map(function (workplace) { return workplace.id; })
                ),"value":event.workplace_id,"name":"workplace_id","name-prefix":index + '_',"rules":"required","errors":_vm.getValidationErrorsForEvent(index),"label":_vm.$tc('sidebar.workplaces', 1)},on:{"onValueChange":function (value) {
                  event.workplace_id = parseInt(value, 10);
                }}})],1):_vm._e(),(!_vm.closingForm && event.service.machine_model)?_c('div',{staticClass:"col-12"},[_c('select-input',{attrs:{"options":_vm.machinesAsSelectOptions(
                  _vm.locationId,
                  event.service.machine_model.id
                ),"allow-empty":true,"value":event.machine_id,"name":"machine_id","name-prefix":index + '_',"errors":_vm.getValidationErrorsForEvent(index),"label":_vm.$tc('sidebar.resources', 1)},on:{"onValueChange":function (value) {
                  event.machine_id = value;
                }}})],1):_vm._e()])]),_c('div',{staticClass:"d-flex flex-md-column justify-content-md-between"},[_c('div',[_c('button',{staticClass:"btn btn-white",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteEvent(index)}}},[_c('i',{staticClass:"fas fa-trash mr-0"})])]),(!_vm.isFromLPittet)?_c('button',{staticClass:"btn btn-white",attrs:{"type":"button"},on:{"click":function($event){return _vm.openAddEventsModal()}}},[_c('i',{staticClass:"fas fa-plus mr-0"})]):_vm._e()])])])}),(_vm.events.length === 0 && !_vm.loading)?_c('button',{staticClass:"btn btn-outline-primary",attrs:{"title":_vm.customerId ? null : _vm.$t('booking.customerRequired.tooltip'),"type":"button","disabled":!_vm.customerId || !_vm.locationId},on:{"click":function($event){return _vm.openAddEventsModal()}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('button.add'))+" ")]):_vm._e(),(_vm.loading)?_c('loading-indicator'):_vm._e(),_c('select-services-modal',{attrs:{"selected-events":_vm.events,"location-id":_vm.locationId,"employee-id":_vm.employeeId,"single-selection":_vm.isFromLPittet},on:{"addServices":_vm.addEvents}}),_c('b-modal',{attrs:{"id":"child-services-modal","title":_vm.$t('services.childServicesModal.title'),"centered":"","ok-only":""},on:{"ok":function($event){return _vm.addEvents(_vm.selectedChildServices)}}},_vm._l((_vm.childServices),function(service){return _c('booking-choice',{key:service.id,attrs:{"selected":_vm.isChildServiceSelected(service)},on:{"onSelectItem":function($event){return _vm.toggleChildService($event, service)}}},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-9"},[_c('div',[_vm._v(_vm._s(service.no)+". "+_vm._s(service.name))]),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(service.description)+" ")])]),_c('div',{staticClass:"col-md-3 text-md-right"},[_vm._v("CHF "+_vm._s(service.price))])])])}),1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }