var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"swipe",rawName:"v-swipe",value:(_vm.onSwipe),expression:"onSwipe"}],staticClass:"tw-relative"},[(_vm.nowBarVisible)?_c('div',{staticClass:"tw-absolute tw-h-0.5 tw-bg-primary-gradient tw-z-1",style:({
      top: _vm.nowBarTopOffset,
      left: _vm.nowBarLeftOffset,
      width: _vm.getItemWidth()
    })},[_c('div',{staticClass:"tw-w-2.5 tw-h-2.5 tw-rounded-full tw-bg-primary-gradient tw-absolute tw--left-0.5 tw--top-1"})]):_vm._e(),_c('div',{staticClass:"tw-relative tw-w-full-12 tw-left-0 tw-ml-12",class:{ 'tw-bg-gray-100': !_vm.schedule }},_vm._l((_vm.gridBlocks),function(gridBlock,i){return _c('div',{key:i,ref:"gridBlock",refInFor:true,staticClass:"tw-relative tw--left-2 tw-w-full+2 tw-border-t-2 tw-border-gray-100",style:({
        height:
          i < _vm.gridBlocks.length - 1
            ? _vm.hourGridBlockHeightInPx + 'px'
            : undefined
      })},[(i < _vm.gridBlocks.length - 1)?_c('div',{staticClass:"tw-flex tw-ml-3"},_vm._l((_vm.currentWeekDays),function(day){return _c('div',{key:day.weekday(),staticClass:"tw-flex-1 tw-border-l-2 tw-border-gray-100",style:({ height: _vm.hourGridBlockHeightInPx + 'px' })})}),0):_vm._e(),_c('small',{staticClass:"tw-absolute tw--left-10 tw--top-3"},[_vm._v(_vm._s(_vm._f("date")(gridBlock,'HH:mm')))])])}),0),(_vm.selectedEmployee && !_vm.selectedEmployee.events_out_of_schedule)?_c('div',_vm._l((_vm.currentWeekDays),function(day){return _c('div',{key:day.toJSON()},_vm._l((_vm.getUnavailableTimeSlots(
          day.format(_vm.API_DATE_FORMAT),
          _vm.selectedEmployee
        )),function(slot){return _c('div',{key:slot.start.toISOString(),staticClass:"tw-absolute tw-bg-gray-100 tw-m-1 tw-rounded",style:({
          top: _vm.getItemTopOffset(slot.start),
          left: _vm.getItemLeftOffset(slot.start),
          height:
            _vm.getItemHeight(slot.start, slot.end) < 400
              ? _vm.getItemHeight(slot.start, slot.end) - 8 + 'px'
              : _vm.getItemHeight(slot.start, slot.end) - 7 + 'px',
          width: _vm.getItemWidth(false)
        })})}),0)}),0):_vm._e(),_c('div',{staticClass:"tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-overflow-hidden"},_vm._l((_vm.calendarEvents),function(calendarEvent,k){return _c('div',{key:calendarEvent.start + '_' + calendarEvent.event_id,staticClass:"tw-absolute",style:({
        top:
          (_vm.getDiffInMinutesFromDayStart(calendarEvent.start) / 60) *
            _vm.hourGridBlockHeightInPx +
          1 +
          'px',
        left: _vm.getItemLeftOffset(calendarEvent.start),
        width: _vm.getItemWidth()
      })},[_c('event-card',{attrs:{"mode":_vm.displayType,"calendar-event":calendarEvent,"custom-height-in-px":_vm.getItemHeight(calendarEvent.start, calendarEvent.end),"z-index":_vm.calendarEvents.length - k}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }