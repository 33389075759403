


































import customerService from '@/services/customer.service';
import moment from 'moment';
import CustomerAddModal from '@/components/customers/CustomerAddModal.vue';
import FormInputMixin from '@/mixins/FormInputMixin';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import Vue, { PropType, VueConstructor } from 'vue';
import { Customer } from '@/model/customer';
import { useUsersStore } from '@/store/users-store';
import { mapStores } from 'pinia';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  components: { CustomerAddModal, VueTypeaheadBootstrap },
  mixins: [FormInputMixin],
  props: {
    defaultCustomer: {
      type: Object as PropType<Customer>,
      default: () => {}
    },
    rules: {
      type: String,
      default: ''
    },
    errors: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchTerms: null as string | null,
      customers: [] as Customer[]
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    }
  },
  watch: {
    searchTerms: function() {
      setTimeout(() => {
        this.fetchUsers();
      }, 300);
    }
  },
  created() {
    if (this.defaultCustomer && Object.keys(this.defaultCustomer).length > 0) {
      this.searchTerms = this.getDescription(this.defaultCustomer);
    }
  },
  methods: {
    async fetchUsers() {
      try {
        this.customers = (
          await customerService.getAll(1, this.searchTerms, null)
        ).customers;
      } catch (exception) {
        console.error(exception);
      }
    },
    selectCustomer(customer) {
      this.searchTerms = this.getDescription(customer);
      this.$emit('onSelectCustomer', customer);
    },
    getDescription(customer) {
      let description = `${customer.firstname} ${customer.lastname} #${customer.id}`;
      if (customer.birthdate) {
        description += ` (${moment(customer.birthdate).format('DD.MM.YYYY')})`;
      }
      return description;
    },
    openAddCustomerModal() {
      this.$bvModal.show('customer-add-modal');
    },
    async afterCustomerAdd(customerId) {
      const customer = await customerService.getById(customerId);
      this.searchTerms = this.getDescription(customer);
      this.$emit('onSelectCustomer', customer);
    }
  }
});
