var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'tw-px-1': !_vm.calendarMode }},[(!_vm.weekday.flexible)?_c('div',{staticClass:"tw-grid tw-gap-3 md:tw-grid-cols-3 xl:tw-grid-cols-4",class:{
      'tw-grid-cols-2': _vm.isFromLPittet,
      'tw-grid-cols-3': !_vm.isFromLPittet,
      'tw-pt-2': !_vm.reduced
    }},_vm._l((_vm.timeRanges),function(timeRange,index){return _c('div',{key:timeRange.start,staticClass:"tw-col-span-1 tw-text-xs tw-mt-2 tw-relative"},[_c('div',{staticClass:"tw-font-bold"},[_vm._v(" "+_vm._s(_vm._f("date")(timeRange.start,'HH:mm'))+" - "+_vm._s(_vm._f("date")(timeRange.end,'HH:mm'))+" ")]),(_vm.locations.length > 1)?_c('div',{staticClass:"tw-flex tw-items-center tw-text-gray-600 tw-mt-1"},[_c('div',{staticClass:"tw-text-center"},[_c('i',{staticClass:"fas fa-map-marker-alt tw-mr-1.5",class:'tw-text-' + timeRange.location.color + '-500'})]),_c('span',{staticClass:"tw-inline-block tw-truncate"},[_vm._v(_vm._s(timeRange.location.name))])]):_vm._e(),(timeRange.own_category)?_c('div',{staticClass:"tw-flex tw-items-center tw-text-gray-600 tw-mt-1"},[_c('i',{staticClass:"fas fa-car tw-mr-1"}),_c('span',{staticClass:"tw-inline-block tw-truncate"},[_vm._v(_vm._s(timeRange.own_category.name))])]):_vm._e(),(
          _vm.isFromLPittet
            ? (index + 1) % 2 !== 0 && index !== _vm.timeRanges.length - 1
            : (index + 1) % 3 !== 0 && index !== _vm.timeRanges.length - 1
        )?_c('div',{staticClass:"tw-w-px tw-bg-gray-500 tw-absolute tw--right-1 tw--top-0.5",class:_vm.locations.length > 1 ? 'tw-h-10' : 'tw-h-5'}):_vm._e()])}),0):_vm._e(),(_vm.weekday.flexible)?_c('div',[_c('div',{staticClass:"tw-grid tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4 tw-pt-3"},[_c('div',{staticClass:"tw-col-span-1 tw-text-sm tw-leading-none"},[_vm._v(" "+_vm._s(_vm.$t('timetable.min_start'))),_c('br'),_vm._v(_vm._s(_vm.$t('timetable.from'))+" "),_c('b',[_vm._v(_vm._s(_vm._f("date")(_vm.weekday.min_start,'HH:mm')))])]),_c('div',{staticClass:"tw-col-span-1 tw-text-sm tw-leading-none"},[_vm._v(" "+_vm._s(_vm.$t('timetable.max_end'))),_c('br'),_vm._v(_vm._s(_vm.$t('timetable.until'))+" "),_c('b',[_vm._v(_vm._s(_vm._f("date")(_vm.weekday.max_end,'HH:mm')))])])]),_c('div',{staticClass:"tw-grid tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-3 tw-pt-3"},_vm._l((_vm.timeRanges),function(timeRange,index){return _c('div',{key:timeRange.start,staticClass:"tw-col-span-1 tw-text-xs tw-relative"},[_c('div',{staticClass:"tw-border-2 tw-rounded-xl tw-py-2 tw-px-2.5",class:{
            'tw-border-gray-300 tw-text-gray-300': !_vm.isTimeRangeOpen(
              timeRange
            ),
            'tw-border-green-800': _vm.isTimeRangeOpen(timeRange)
          }},[_c('div',{staticClass:"tw-flex tw-justify-between tw-uppercase tw-font-bold"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('timetable.block'))+" "+_vm._s(index + 1)+" ("+_vm._s(_vm._f("date")(timeRange.duration,'H'))+"h"),(_vm.moment(timeRange.duration).minutes() > 0)?_c('span',[_vm._v(_vm._s(_vm._f("date")(timeRange.duration,'mm')))]):_vm._e(),_vm._v(") ")]),(_vm.isTimeRangeOpen(timeRange) && _vm.weekday.capacity_threshold)?_c('div',{staticClass:"tw-text-right tw-font-bold tw-text-green-800"},[_vm._v(" "+_vm._s(_vm.capacitiesPerTimeRange[index])+"% ")]):_vm._e()]),_c('div',{staticClass:"tw-pt-1"},[(_vm.isTimeRangeOpen(timeRange))?_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(timeRange.start,'HH:mm'))+" - "+_vm._s(_vm._f("date")(timeRange.end,'HH:mm'))+" ")]):_vm._e(),(_vm.isTimeRangeLocked(timeRange))?_c('i',{staticClass:"ml-1 fas fa-check"}):_vm._e(),(!_vm.isTimeRangeLocked(timeRange))?_c('i',{staticClass:"fa fa-hourglass-half tw-transform tw--rotate-22",class:{ 'tw-ml-1': _vm.isTimeRangeOpen(timeRange) }}):_vm._e()]),_c('div',{staticClass:"tw-flex tw-items-center tw-mt-1",class:{
              'tw-text-gray-600': _vm.isTimeRangeOpen(timeRange),
              'tw-hidden': _vm.locations.length <= 1
            }},[_c('div',{staticClass:"tw-text-center"},[_c('i',{staticClass:"fas fa-map-marker-alt tw-mr-1.5",class:'tw-text-' + timeRange.location.color + '-500'})]),_c('span',{staticClass:"tw-inline-block tw-truncate"},[_vm._v(_vm._s(timeRange.location.name))])])])])}),0),(_vm.weekday.capacity_threshold)?_c('div',{staticClass:"tw-text-sm tw-leading-none tw-pt-3"},[_vm._v(" Le "),_c('span',{staticClass:"tw-text-green-800"},[_vm._v("bloc actuel")]),_vm._v(" doit être rempli à "),_c('span',{staticClass:"tw-underline"},[_vm._v(_vm._s(_vm.weekday.capacity_threshold * 100)+"%")]),_vm._v(" pour que le prochain bloc s'ouvre. ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }