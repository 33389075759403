






















































































































































































































































import SelectInput from '@/components/shared/forms/SelectInput.vue';
import moment from 'moment';
import TimetableHelper from '@/helpers/timetable.helper';
import WeekdayBase from '@/components/shared/WeekdayBase.vue';
import MultiSelectInput from '@/components/shared/forms/MultiSelectInput.vue';
import TextInput from '@/components/shared/forms/TextInput.vue';
import RadioInput from '@/components/shared/forms/RadioInput.vue';
import TimeInput from '@/components/shared/forms/TimeInput.vue';
import cloneDeep from 'lodash/cloneDeep';
import Vue, { PropType, VueConstructor } from 'vue';
import { Weekday } from '@/model/weekday';
import { InputOption } from '@/model/input-option';
import { TimetableTimeRange } from '@/model/timetable-time-range';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useCategoriesStore } from '@/store/categories-store';
import { useUsersStore } from '@/store/users-store';
import { useTimetablesStore } from '@/store/timetables-store';
import { DEFAULT_TIME_SLOT } from '@/constants';
import { useLocationsStore } from '@/store/locations-store';
import { Category } from '@/model/category';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof WeekdayBase>
>).extend({
  components: {
    TextInput,
    MultiSelectInput,
    SelectInput,
    RadioInput,
    TimeInput
  },
  extends: WeekdayBase,
  props: {
    isException: {
      type: Boolean,
      default: false
    },
    firstAvailableWeekdayIndex: {
      type: Number,
      required: false,
      default: undefined
    },
    weekdayIndex: {
      type: Number,
      required: false,
      default: undefined
    },
    weekday: {
      // Les données du formulaire transmises par le parent
      type: Object as PropType<Partial<Weekday>>,
      required: false,
      default: () => ({} as Partial<Weekday>)
    },
    showLocations: {
      type: Boolean,
      default: true
    },
    validationErrors: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      timetableTypeOptions: [
        {
          value: '0',
          label: this.$i18n.t('label.notFlexibleTimetable')
        },
        {
          value: '1',
          label: this.$i18n.t('label.flexibleTimetable')
        }
      ] as InputOption[],
      form: {
        weekday: !this.isException ? this.weekdayIndex + 1 : undefined,
        date: '',
        summary: '',
        available: this.weekday.available ?? false,
        flexible: this.weekday.flexible ?? false,
        min_start: this.weekday.min_start ?? '',
        max_end: this.weekday.max_end ?? '',
        capacity_threshold: this.weekday.capacity_threshold ?? 0,
        time_ranges: this.weekday.time_ranges ?? ([] as TimetableTimeRange[])
      }
    };
  },
  computed: {
    ...mapStores(
      useEmployeesStore,
      useCategoriesStore,
      useUsersStore,
      useTimetablesStore,
      useLocationsStore
    ),
    locations(): InputOption[] {
      return this.locationsStore.getLocationsAsSelectOptions;
    },
    categoriesOptions(): InputOption[] {
      return this.categoriesStore.getAsSelectOptions;
    },
    categories(): Category[] {
      return this.categoriesStore.entities;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    },
    isFromHarmony(): boolean {
      return this.usersStore.isFromHarmony;
    },
    isFirstAvailableWeekday(): boolean {
      // Pour afficher le bouton "Répéter pour les jours suivants"
      return this.weekdayIndex === this.firstAvailableWeekdayIndex;
    },
    dailyHoursPlanned(): number {
      return +(
        TimetableHelper.getMinutesPlannedForTimeSlots(this.form.time_ranges) /
        60
      ).toFixed(2);
    },
    weekdayName(): string {
      return moment.weekdays(true)[this.weekdayIndex];
    },
    dayDto(): any {
      const dto = cloneDeep(this.form);
      dto.flexible = dto.flexible === '1' || dto.flexible === true;
      dto.capacity_threshold = dto.capacity_threshold
        ? parseInt(dto.capacity_threshold) / 100
        : 0;
      for (let j = 0; j < dto.time_ranges.length; j++) {
        if (dto.time_ranges[j].possible_categories) {
          dto.time_ranges[j].possible_categories = dto.time_ranges[
            j
          ].possible_categories.map(categoryOption => ({
            id: categoryOption.value
          }));
        }
      }

      // La pause doit être assignée en fin de bloc et pas au début du suivant (mais plus logique UX d'avoir la pause dans le 2è bloc)
      dto.time_ranges = dto.time_ranges.map((timeRange, timeRangeIndex) => {
        // Il faut supprimer le lieu, sinon cela crée une erreur lors du marshalling à l'api
        delete timeRange.location;
        if (!dto.flexible) {
          return timeRange;
        } else {
          return {
            ...timeRange,
            break: dto.time_ranges[timeRangeIndex + 1]
              ? dto.time_ranges[timeRangeIndex + 1].break
              : null,
            position: timeRangeIndex + 1
          };
        }
      });

      if (this.isException) {
        dto.time_range_exceptions = dto.time_ranges;
        delete dto.time_ranges;
      }

      return dto;
    }
  },
  watch: {
    /**
     * Pour repeatForNextDays
     */
    weekday(newForm) {
      this.form = newForm;
    }
  },
  methods: {
    getValidationErrorsForTimeRange(timeRangeIndex: number) {
      return Object.keys(this.validationErrors).length > 0 &&
        this.validationErrors.time_ranges
        ? this.validationErrors.time_ranges[timeRangeIndex]
        : {};
    },
    updateProperty(propertyName: string, value = null) {
      if (propertyName !== 'available') {
        // Boolean pas bien retourné par le checkbox
        this.form[propertyName] = value;
      }
      if (propertyName === 'available') {
        this.form.available = !this.form.available;
        if (this.form.available) {
          this.addTimeRange();
        } else {
          this.form.time_ranges = [];
        }
      } else if (propertyName === 'flexible') {
        this.form.time_ranges = [];
        this.addTimeRange();
      }
      this.$emit('onDayChanged', this.form);
    },
    addTimeRange(atIndex: number = 0) {
      this.form.time_ranges.splice(atIndex, 0, cloneDeep(DEFAULT_TIME_SLOT));
    },
    deleteTimeRange(index: number) {
      this.form.time_ranges.splice(index, 1);
    },
    updateTimeRange(index: number, propertyName: number, value) {
      this.form.time_ranges[index][propertyName] = value;
      this.$emit('onDayChanged', this.form);
    },
    repeatForNextDays() {
      this.$emit('repeatForNextDays');
    },
    availableOwnCategories(index: number): InputOption[] {
      return this.categoriesOptions.filter(
        category =>
          !this.form.time_ranges[index] ||
          !this.form.time_ranges[index].possible_categories ||
          !this.form.time_ranges[index].possible_categories.some(
            cat => cat.id === parseInt(category.value, 10)
          )
      );
    },
    availablePossibleCategories(index: number): Category[] {
      return this.categories.filter(
        category =>
          !this.form.time_ranges[index].own_category_id ||
          this.form.time_ranges[index].own_category_id !== category.id
      );
    }
  }
});
