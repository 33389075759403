


































import { TIME_FORMAT } from '@/filters/time';
import employeeService from '@/services/employee.service';
import bookingService from '@/services/booking.service';
import EventForm from '@/components/calendar/EventForm.vue';
import BookingForm from '@/components/calendar/BookingForm.vue';
import legalConditionsService from '@/services/legal-conditions.service';
import moment from 'moment';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { CalendarResourceType, useCalendarStore } from '@/store/calendar-store';
import { useVendorsStore } from '@/store/vendors-store';
import { useUsersStore } from '@/store/users-store';
import { Vendor } from '@/model/vendor';
import { API_DATE_FORMAT } from '@/constants';
import { useEmployeesStore } from '@/store/employees-store';
import { AlertType, useUiStore } from '@/store/ui-store';

export default Vue.extend({
  components: {
    BookingForm,
    EventForm
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    entityId: {
      type: Number,
      required: true
    },
    eventOnly: {
      type: Boolean,
      default: false
    },
    summary: {
      type: String,
      required: false,
      default: ''
    },
    bookingView: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      sendingData: false,
      validationErrors: {} as any
    };
  },
  computed: {
    ...mapStores(
      useCalendarStore,
      useVendorsStore,
      useUsersStore,
      useEmployeesStore,
      useUiStore
    ),
    viewType(): CalendarResourceType {
      return this.calendarStore.viewType;
    },
    vendor(): Vendor {
      return this.vendorsStore.currentEntity;
    },
    vendorId(): number {
      return this.usersStore.loggedInVendorId;
    }
  },
  methods: {
    async onSave(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.bookingView) {
        (this.$refs.bookingForm as any).onSubmit();
      } else {
        this.addEvent();
      }
    },
    async addEvent() {
      this.sendingData = true;
      try {
        const eventForm = this.$refs.eventForm as any;
        const data = await employeeService.addEvent(eventForm.dataModelForApi);
        this.calendarStore.addEvent(
          {
            ...(this.$refs.eventForm as any).dataModelForApi,
            id: data.id,
            start: moment(eventForm.form.date, API_DATE_FORMAT)
              .hours(moment(eventForm.form.start, TIME_FORMAT).hours())
              .minutes(moment(eventForm.form.start, TIME_FORMAT).minutes())
              .toISOString(true),
            end: moment(eventForm.form.date, API_DATE_FORMAT)
              .hours(moment(eventForm.form.end, TIME_FORMAT).hours())
              .minutes(moment(eventForm.form.end, TIME_FORMAT).minutes())
              .toISOString(true)
          },
          eventForm.form.date,
          this.entityId
        );

        this.calendarStore.findEventsBetweenDates({
          employeeId:
            this.viewType === CalendarResourceType.EMPLOYEE
              ? this.entityId
              : null,
          workplaceId:
            this.viewType === CalendarResourceType.WORKPLACE
              ? this.entityId
              : null,
          machineId:
            this.viewType === CalendarResourceType.MACHINE
              ? this.entityId
              : null,
          dateFrom: eventForm.form.date,
          dateTo: eventForm.form.date
        });
        this.sendingData = false;
        this.$bvModal.hide('event-add-modal-' + this.index);
      } catch (exception) {
        this.sendingData = false;
        this.uiStore.alert(exception.message);
        if (exception.errors !== undefined) {
          this.validationErrors = exception.errors;
        }
      }
    },
    async addBooking(addBookingDto) {
      try {
        this.sendingData = true;
        this.validationErrors = {};
        const time = moment(addBookingDto.date)
          .hours(parseInt(addBookingDto.start.split(':')[0], 10))
          .minutes(parseInt(addBookingDto.start.split(':')[1], 10));
        for (let i = 0; i < addBookingDto.events.length; i++) {
          addBookingDto.events[i].manual = true;
          addBookingDto.events[i].start = time.format();
          addBookingDto.events[i].customer_start = time.format();
          addBookingDto.events[i].customer_end = time
            .add(addBookingDto.events[i].effective_duration, 'minutes')
            .format();
          if (i === addBookingDto.events.length - 1)
            time.add(this.vendor.client_exit_duration_in_minutes, 'minutes');
          addBookingDto.events[i].end = time.format();
        }
        const legalConditions = await legalConditionsService.findAll(
          this.vendorId
        );
        addBookingDto.acceptedLegalConditionsIds = legalConditions.map(
          legalCondition => legalCondition.id
        );
        await bookingService.save(addBookingDto.customer.id, addBookingDto);

        this.calendarStore.findEventsBetweenDates({
          employeeId: this.employeesStore.currentEntity.id,
          dateFrom: addBookingDto.date,
          dateTo: addBookingDto.date
        });
        this.calendarStore.findSchedulesBetweenDates({
          employeeId: this.employeesStore.currentEntity.id,
          dateFrom: addBookingDto.date,
          dateTo: addBookingDto.date
        });
        this.$bvModal.hide('event-add-modal-' + this.index);
      } catch (exception) {
        this.uiStore.alert(
          exception.message,
          AlertType.DANGER,
          10,
          exception.errors
        );
        if (exception.errors !== undefined) {
          this.validationErrors = exception.errors;
        }
      } finally {
        this.sendingData = false;
      }
    }
  }
});
