var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"novalidate":""}},[_c('radio-input',{attrs:{"default-value":_vm.customer.gender,"options":[
      { value: _vm.GENDER.FEMALE, label: _vm.$t('label.mrs') },
      { value: _vm.GENDER.MALE, label: _vm.$t('label.mr') },
      { value: _vm.GENDER.OTHER, label: _vm.$t('label.genderOther') }
    ],"errors":_vm.errors,"inline":true,"name":"gender","label":_vm.$t('label.gender')},on:{"onValueChange":function (value) {
        _vm.customer.gender = value;
      }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('text-input',{attrs:{"value":_vm.customer.firstname,"errors":_vm.errors,"name":"firstname","label":_vm.$t('label.firstname')},on:{"onValueChange":function (value) {
            _vm.customer.firstname = value;
          }}})],1),_c('div',{staticClass:"col-md-6"},[_c('text-input',{attrs:{"value":_vm.customer.lastname,"errors":_vm.errors,"name":"lastname","label":_vm.$t('label.lastname')},on:{"onValueChange":function (value) {
            _vm.customer.lastname = value;
          }}})],1)]),_c('text-input',{attrs:{"value":_vm.customer.email,"errors":_vm.errors,"name":"email","label":_vm.$t('label.email'),"type":"email"},on:{"onValueChange":function (value) {
        _vm.customer.email = value;
      }}}),_c('text-input',{attrs:{"value":_vm.customer.address,"errors":_vm.errors,"name":"address","optional":true,"label":_vm.$t('label.address')},on:{"onValueChange":function (value) {
        _vm.customer.address = value;
      }}}),_c('text-input',{attrs:{"value":_vm.customer.addressComplement,"errors":_vm.errors,"name":"addressComplement","optional":true,"label":_vm.$t('label.addressComplement')},on:{"onValueChange":function (value) {
        _vm.customer.addressComplement = value;
      }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('text-input',{attrs:{"value":_vm.customer.zip,"errors":_vm.errors,"name":"zip","optional":true,"label":_vm.$t('label.zip')},on:{"onValueChange":function (value) {
            _vm.customer.zip = value;
          }}})],1),_c('div',{staticClass:"col-8"},[_c('text-input',{attrs:{"value":_vm.customer.city,"errors":_vm.errors,"name":"city","optional":true,"label":_vm.$t('label.city')},on:{"onValueChange":function (value) {
            _vm.customer.city = value;
          }}})],1)]),_c('text-input',{attrs:{"value":_vm.customer.country,"errors":_vm.errors,"name":"country","optional":true,"label":_vm.$t('label.country')},on:{"onValueChange":function (value) {
        _vm.customer.country = value;
      }}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('date-input',{attrs:{"value":_vm.customer.birthdate,"errors":_vm.errors,"name":"birthdate","optional":true,"label":_vm.$t('label.birthdate')},on:{"onValueChange":function (value) {
            _vm.customer.birthdate = value;
          }}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('phone-input',{attrs:{"value":_vm.customer.mobileProPhone,"errors":_vm.errors,"name":"mobileProPhone","optional":true,"label":_vm.$t('label.mobileProPhone')},on:{"onValueChange":function (value) {
            _vm.customer.mobileProPhone = value;
          }}})],1),_c('div',{staticClass:"col-md-6"},[_c('phone-input',{attrs:{"value":_vm.customer.workPhone,"errors":_vm.errors,"name":"workPhone","optional":true,"label":_vm.$t('label.workPhone')},on:{"onValueChange":function (value) {
            _vm.customer.workPhone = value;
          }}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('phone-input',{attrs:{"value":_vm.customer.mobilePhone,"errors":_vm.errors,"name":"mobilePhone","label":_vm.$t('label.mobilePhone')},on:{"onValueChange":function (value) {
            _vm.customer.mobilePhone = value;
          }}})],1),_c('div',{staticClass:"col-md-6"},[_c('phone-input',{attrs:{"value":_vm.customer.homePhone,"errors":_vm.errors,"name":"homePhone","optional":true,"label":_vm.$t('label.homePhone')},on:{"onValueChange":function (value) {
            _vm.customer.homePhone = value;
          }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }