import Vue from 'vue';

export default Vue.extend({
  props: {
    // Label (name) personnalisé, qui remplacera le name du array of objects
    optionLabel: {
      type: String,
      default: ''
    },
    // Valeur personnalisée, qui remplacera le name du array of objects
    optionValue: {
      type: String,
      default: ''
    }
  },
  methods: {
    getLabel(option: any): string {
      if (!this.optionLabel) {
        return option.label;
      } else {
        if (this.optionLabel.indexOf('.') === -1) {
          return option[this.optionLabel];
        } else {
          const startingFrom = Object.assign({}, option);
          return this.optionLabel
            .split('.')
            .reduce(
              (object, propertyName) => object[propertyName],
              startingFrom
            );
        }
      }
    },
    getValue(option: any): any {
      if (!this.optionValue) {
        return option.value;
      } else {
        if (this.optionValue.indexOf('.') === -1) {
          return option[this.optionValue];
        } else {
          const startingFrom = Object.assign({}, option);
          return this.optionLabel
            .split('.')
            .reduce(
              (object, propertyName) => object[propertyName],
              startingFrom
            );
        }
      }
    }
  }
});
