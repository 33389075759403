




















































































































































































































import TextInput from '@/components/shared/forms/TextInput.vue';
import RadioInput from '@/components/shared/forms/RadioInput.vue';
import DateInput from '@/components/shared/forms/DateInput.vue';
import PhoneInput from '@/components/shared/forms/PhoneInput.vue';
import { Gender } from '@/model/gender';
import Vue from 'vue';
import { Customer } from '@/model/customer';

export default Vue.extend({
  components: {
    TextInput,
    RadioInput,
    DateInput,
    PhoneInput
  },
  props: {
    customerToEdit: {
      type: Object,
      default: () => {}
    },
    errors: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      GENDER: Gender,
      customer: {
        email:
          this.customerToEdit && this.customerToEdit.email
            ? this.customerToEdit.email
            : '',
        gender:
          this.customerToEdit && this.customerToEdit.gender
            ? this.customerToEdit.gender
            : Gender.OTHER,
        lastname:
          this.customerToEdit && this.customerToEdit.lastname
            ? this.customerToEdit.lastname
            : '',
        firstname:
          this.customerToEdit && this.customerToEdit.firstname
            ? this.customerToEdit.firstname
            : '',
        address:
          this.customerToEdit && this.customerToEdit.address
            ? this.customerToEdit.address
            : '',
        addressComplement:
          this.customerToEdit && this.customerToEdit.addressComplement
            ? this.customerToEdit.addressComplement
            : '',
        zip:
          this.customerToEdit && this.customerToEdit.zip
            ? this.customerToEdit.zip
            : '',
        city:
          this.customerToEdit && this.customerToEdit.city
            ? this.customerToEdit.city
            : '',
        country:
          this.customerToEdit && this.customerToEdit.country
            ? this.customerToEdit.country
            : '',
        birthdate:
          this.customerToEdit && this.customerToEdit.birthdate
            ? this.customerToEdit.birthdate
            : '',
        mobileProPhone:
          this.customerToEdit && this.customerToEdit.mobileProPhone
            ? this.customerToEdit.mobileProPhone
            : '',
        workPhone:
          this.customerToEdit && this.customerToEdit.workPhone
            ? this.customerToEdit.workPhone
            : '',
        mobilePhone:
          this.customerToEdit && this.customerToEdit.mobilePhone
            ? this.customerToEdit.mobilePhone
            : '',
        homePhone:
          this.customerToEdit && this.customerToEdit.homePhone
            ? this.customerToEdit.homePhone
            : ''
      }
    };
  },
  computed: {
    dataModelForApi(): Partial<Customer> {
      const customer: Partial<Customer> = {
        ...this.customer
      };
      if (this.customerToEdit) {
        customer.id = this.customerToEdit.id;
      }
      return customer;
    }
  }
});
