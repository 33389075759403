














































import moment from 'moment';
import { TIME_FORMAT } from '@/filters/time';
import employeeService from '@/services/employee.service';
import DateInput from '@/components/shared/forms/DateInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import { ValidationObserver } from 'vee-validate';
import TimetableDayForm from '@/components/timetables/TimetableDayForm.vue';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Vue, { PropType } from 'vue';
import { TimetableDateException } from '@/model/timetable-date-exception';
import { Role } from '@/model/role';
import { Employee } from '@/model/employee';
import { InputOption } from '@/model/input-option';
import { mapStores } from 'pinia';
import { useEmployeesStore } from '@/store/employees-store';
import { useCategoriesStore } from '@/store/categories-store';
import { useUsersStore } from '@/store/users-store';
import { useLocationsStore } from '@/store/locations-store';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';
import { Schedule } from '@/model/schedule';
import { API_DATE_FORMAT } from '@/constants';
import { Timetable } from '@/model/timetable';
import { useTimetablesStore } from '@/store/timetables-store';
import { Weekday } from '@/model/weekday';
import { ComputedTimeRange } from '@/model/computed-time-range';
import { TimetableTimeRange } from '@/model/timetable-time-range';
import { TimetableTimeRangeException } from '@/model/timetable-time-range-exception';
import { AlertType, useUiStore } from '@/store/ui-store';

export default Vue.extend({
  components: {
    DateInput,
    SubmitButton,
    ValidationObserver,
    TimetableDayForm,
    CheckEventsConflictsModal,
    LoadingIndicator
  },
  props: {
    editingTimetableException: {
      type: Object as PropType<TimetableDateException | Schedule>,
      required: false,
      default: () => ({} as TimetableDateException | Schedule)
    },
    hiddenSaveButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moment: moment,
      date: '',
      ROLE: Role,
      hovering: {
        topArrow: false,
        bottomArrow: false
      },
      loading: false,
      sendingData: false,
      validationErrors: {},
      today: moment().toDate()
    };
  },
  computed: {
    ...mapStores(
      useEmployeesStore,
      useCategoriesStore,
      useUsersStore,
      useLocationsStore,
      useTimetableExceptionsStore,
      useTimetablesStore,
      useUiStore
    ),
    employee(): Employee {
      return this.employeesStore.currentEntity;
    },
    categories(): InputOption[] {
      return this.categoriesStore.getAsSelectOptions;
    },
    isFromLPittet(): boolean {
      return this.usersStore.isFromLPittet;
    },
    locations(): InputOption[] {
      return this.locationsStore.getLocationsAsSelectOptions;
    },
    timetables(): Timetable[] {
      return this.timetablesStore.entities;
    },
    timetableExceptions(): TimetableDateException[] {
      return this.timetableExceptionsStore.entities;
    },
    willOverride(): boolean {
      if (this.date) {
        const date = moment(this.date);
        if (moment(this.editingTimetableException.date).isSame(date)) {
          return true;
        } else {
          return (
            this.timetableExceptionsStore.entities.findIndex(
              timetableException => {
                return moment(timetableException.date).isSame(date, 'day');
              }
            ) !== -1
          );
        }
      } else return false;
    }
  },
  watch: {
    editingTimetableException(): void {
      this.updateDate(this.editingTimetableException.date);
    }
  },
  async created() {
    if (Object.keys(this.editingTimetableException).length > 0) {
      if ((this.editingTimetableException as Schedule).employeeId) {
        /**
         * === instanceof Schedule
         * Depuis "Calendrier"
         * Spécial car on a une Schedule et on doit passer au formulaire un Weekday ou TimetableDateException
         */
        this.loading = true;
        this.updateDate(this.editingTimetableException.date, false);
        try {
          // Date Exception existe ?
          const dateException = await employeeService.findOneTimetableDateException(
            this.editingTimetableException.date,
            (this.editingTimetableException as Schedule).employeeId
          );
          this.setWeekdayOrDateExceptionValues(dateException);
          this.loading = false;
        } catch (exception) {
          try {
            // Weekday existe ?
            const weekday = await employeeService.findOneWeekday(
              this.editingTimetableException.date,
              (this.editingTimetableException as Schedule).employeeId
            );
            this.setWeekdayOrDateExceptionValues(weekday);
            this.loading = false;
          } catch (exception) {
            // Jour ni planifié ni date exception
            this.loading = false;
            console.error(exception);
          }
        }
      } else {
        // === instance of TimetableDateException
        this.updateDate(this.editingTimetableException.date);
      }
    }
  },
  methods: {
    getTimetableExceptions() {
      this.timetableExceptionsStore.findAll(this.employee.id);
    },
    /**
     * Permet de mettre par défaut selon l'horaire actuel ou l'exception prévue actuellement
     */
    setDefaultValues() {
      if (Object.keys(this.editingTimetableException).length > 0) {
        this.setWeekdayOrDateExceptionValues(this.editingTimetableException);
      } else {
        if (!this.date) {
          this.date = moment().format(API_DATE_FORMAT);
        }
        const timetableExceptionForTheSameDate = this.timetableExceptions.find(
          timetableException => timetableException.date === this.date
        );
        if (timetableExceptionForTheSameDate) {
          /**
           * B. Une exception existe déjà ce jour-là
           */
          this.setWeekdayOrDateExceptionValues(
            timetableExceptionForTheSameDate
          );
        } else if (this.timetableExceptions.length > 0) {
          /*
           * C. On reprend l'horaire classique pour ce jour
           */
          const timetableUsedOnSelectedDate = this.timetables.find(
            timetable => {
              return (
                moment(timetable.valid_from) <= moment(this.date) &&
                !this.timetables.find(timetableFromLoop => {
                  return (
                    moment(timetableFromLoop.valid_from) <= moment(this.date) &&
                    moment(timetableFromLoop.valid_from).isAfter(
                      moment(timetable.valid_from)
                    )
                  );
                })
              );
            }
          );
          if (timetableUsedOnSelectedDate) {
            const currentTimetableWeekday =
              timetableUsedOnSelectedDate.weekdays[moment(this.date).weekday()];
            if (currentTimetableWeekday) {
              this.setWeekdayOrDateExceptionValues(currentTimetableWeekday);
            }
          }
        }
      }
    },
    updateDate(value: string, setDefaultValues = true) {
      this.date = value;
      if (setDefaultValues) {
        this.$nextTick(() => {
          (this.$refs.dayForm as any).form.date = value;
          this.setDefaultValues();
        });
      }
    },
    setWeekdayOrDateExceptionValues(
      weekdayOrDateException: Weekday | TimetableDateException | Schedule
    ) {
      const childForm = (this.$refs.dayForm as any).form;
      childForm.available = weekdayOrDateException.available;
      childForm.flexible = weekdayOrDateException.flexible;
      childForm.summary =
        (weekdayOrDateException as TimetableDateException).summary || '';
      childForm.min_start = weekdayOrDateException.min_start
        ? moment(weekdayOrDateException.min_start).format(TIME_FORMAT)
        : '';
      childForm.max_end = weekdayOrDateException.max_end
        ? moment(weekdayOrDateException.max_end).format(TIME_FORMAT)
        : '';
      childForm.capacity_threshold =
        weekdayOrDateException.capacity_threshold * 100;
      const timeRanges =
        (weekdayOrDateException as Schedule).timeRanges ??
        (weekdayOrDateException as Weekday).time_ranges ??
        (weekdayOrDateException as TimetableDateException)
          .time_range_exceptions ??
        [];
      // @ts-ignore
      childForm.time_ranges = timeRanges.map(
        (
          timeRange:
            | ComputedTimeRange
            | TimetableTimeRange
            | TimetableTimeRangeException,
          timeRangeIndex: number
        ) => {
          return {
            start: timeRange.start
              ? moment(timeRange.start).format(TIME_FORMAT)
              : '',
            end: timeRange.end ? moment(timeRange.end).format(TIME_FORMAT) : '',
            location_id: (timeRange as
              | TimetableTimeRange
              | TimetableTimeRangeException).location_id,
            duration: (timeRange as
              | TimetableTimeRange
              | TimetableTimeRangeException).duration
              ? moment(
                  (timeRange as
                    | TimetableTimeRange
                    | TimetableTimeRangeException).duration
                ).format(TIME_FORMAT)
              : '',
            break:
              timeRangeIndex > 0 &&
              (timeRanges[timeRangeIndex - 1] as
                | TimetableTimeRange
                | TimetableTimeRangeException).break
                ? moment(
                    (timeRanges[timeRangeIndex - 1] as
                      | TimetableTimeRange
                      | TimetableTimeRangeException).break
                  ).format(TIME_FORMAT)
                : '',
            position: (timeRange as
              | TimetableTimeRange
              | TimetableTimeRangeException).position,
            own_category_id: (timeRange as
              | TimetableTimeRange
              | TimetableTimeRangeException).own_category_id
              ? (timeRange as TimetableTimeRange | TimetableTimeRangeException)
                  .own_category_id
              : undefined,
            possible_categories: timeRange.possible_categories
              ? timeRange.possible_categories
              : undefined
          };
        }
      );
    },
    isEndEditable(timeRangeIndex: number) {
      if (this.isAdmin) {
        return true;
      } else {
        return (
          timeRangeIndex <
            (this.$refs.dayForm as any).form.time_ranges.length - 1 &&
          (this.$refs.dayForm as any).form.time_ranges.length > 1
        );
      }
    },
    async save() {
      const isValid = await (this.$refs.observer as any).validate();
      if (!isValid) return;
      this.validationErrors = {};
      this.$bvModal.show('check-events-conflicts-modal');
    },
    getTimetableDateExceptionDto() {
      const dayDto = (this.$refs.dayForm as any).dayDto;
      return {
        ...dayDto,
        employee_id:
          this.editingTimetableException &&
          (this.editingTimetableException as Schedule).employeeId
            ? (this.editingTimetableException as Schedule).employeeId
            : this.employeesStore.currentEntity.id
      };
    },
    async verifyRequest() {
      await employeeService.verifyAddTimetableDateException(
        this.getTimetableDateExceptionDto()
      );
    },
    async saveRequest() {
      await employeeService.addTimetableDateException(
        this.getTimetableDateExceptionDto()
      );
      if (this.editingTimetableException) {
        this.uiStore.alert(
          this.$i18n.t('toast.timetableExceptionModified'),
          AlertType.SUCCESS
        );
      } else {
        this.uiStore.alert(
          this.$i18n.t('toast.timetableExceptionAdded'),
          AlertType.SUCCESS
        );
      }
      await this.getTimetableExceptions();
      return true;
    },
    setValidationErrors(errors: any, message: string) {
      this.validationErrors = errors;
      this.$bvModal.hide('check-events-conflicts-modal');
      this.uiStore.alert(message);
    },
    async saved() {
      this.$emit('timetableExceptionSaved');
    }
  }
});
