import moment from 'moment';
import { flatten, uniq } from 'lodash';

export default {
  /**
   * Retourne les minutes planifiés pour un jour de la semaine
   *
   * @param timeSlots start et end au format HH:mm
   *
   * @returns {number}
   */
  getMinutesPlannedForTimeSlots(timeSlots) {
    let minutes = 0;
    timeSlots.forEach(timeSlot => {
      const timeSlotMinutes = moment(timeSlot.end, 'HH:mm').diff(
        moment(timeSlot.start, 'HH:mm'),
        'minutes'
      );
      if (!isNaN(timeSlotMinutes)) {
        minutes += timeSlotMinutes;
      }
    });
    return minutes;
  },
  /**
   * Retourne uniquement les horaires actuels et futurs
   *
   * @param timetables timetables array
   *
   * @returns {timetable[]}
   */
  getCurrentAndFutureTimetables(timetables) {
    const timetablesSorted = [...timetables].sort(
      (a, b) => moment(b.valid_from).unix() - moment(a.valid_from).unix()
    );
    const filteredTimetables = [];
    for (let i = 0; i < timetablesSorted.length; i++) {
      filteredTimetables.push(timetablesSorted[i]);
      if (moment(timetablesSorted[i].valid_from).isBefore(moment())) {
        break;
      }
    }
    return filteredTimetables;
  },
  /**
   * Retourne uniquement les horaires spéciaux actuels et futurs
   *
   * @param timetableExceptions timetables array
   *
   * @returns {timetableException[]}
   */
  getCurrentAndFutureTimetableExceptions(timetableExceptions) {
    const timetableExceptionsSorted = [...timetableExceptions].sort(
      (a, b) => moment(b.date).unix() - moment(a.date).unix()
    );
    const filteredTimetableExceptions = [];
    for (let i = 0; i < timetableExceptionsSorted.length; i++) {
      if (
        moment(filteredTimetableExceptions[i].date).isBefore(moment(), 'day')
      ) {
        break;
      }
      filteredTimetableExceptions.push(timetableExceptionsSorted[i]);
    }
    return filteredTimetableExceptions;
  },
  /**
   * Retourne les horaires indexés par locationId
   * @param timetables
   * @returns {{}}
   */
  getTimetablesByLocation(timetables) {
    const timetablesByLocation = {};
    for (let i = 0; i < timetables.length; i++) {
      const locationIds = this.timetableLocationIds(timetables[i]);
      for (let j = 0; j < locationIds.length; j++) {
        if (timetablesByLocation[locationIds[j]]) {
          timetablesByLocation[locationIds[j]].push(timetables[i]);
        } else {
          timetablesByLocation[locationIds[j]] = [timetables[i]];
        }
      }
    }
    return timetablesByLocation;
  },
  /**
   * Retourne les horaires spéciaux indexés par locationId
   * @param timetableExceptions
   * @returns {{}}
   */
  getTimetableExceptionsByLocation(timetableExceptions) {
    const timetableExceptionsByLocation = {};
    for (let i = 0; i < timetableExceptions.length; i++) {
      const locationIds = uniq(
        timetableExceptions[i].time_range_exceptions.map(
          timeRange => timeRange.location_id
        )
      );
      for (let j = 0; j < locationIds.length; j++) {
        if (timetableExceptionsByLocation[locationIds[j]]) {
          timetableExceptionsByLocation[locationIds[j]].push(
            timetableExceptions[i]
          );
        } else {
          timetableExceptionsByLocation[locationIds[j]] = [
            timetableExceptions[i]
          ];
        }
      }
    }
    return timetableExceptionsByLocation;
  },
  timetableLocationIds(timetable) {
    return uniq(
      flatten(
        timetable.weekdays.map(weekday =>
          weekday.time_ranges.map(timeRange => timeRange.location_id)
        )
      )
    );
  }
};
