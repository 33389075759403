















































































































































import CalendarEventModal from '@/components/calendar/CalendarEventModal.vue';
import CalendarEventAddModal from '@/components/calendar/CalendarEventAddModal.vue';
import BaseEventMixin from '@/components/calendar/BaseEventMixin.ts';
import Vue, { PropType, VueConstructor } from 'vue';
import {
  CalendarDisplayType,
  CalendarResourceType,
  useCalendarStore
} from '@/store/calendar-store';
import { mapStores } from 'pinia';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { useLocationsStore } from '@/store/locations-store';
import { CalendarEvent } from '@/components/calendar/models/calendar-event';
import { ColorHelper } from '@/helpers/color-helper';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseEventMixin>
>).extend({
  components: {
    CalendarEventModal,
    CalendarEventAddModal
  },
  mixins: [BaseEventMixin],
  props: {
    mode: {
      type: String as PropType<CalendarDisplayType>,
      required: true
    },
    calendarEvent: {
      type: Object as PropType<CalendarEvent>,
      required: true
    },
    zIndex: {
      type: Number,
      required: true
    },
    customHeightInPx: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      VIEW_TYPE: CalendarResourceType,
      ColorHelper: ColorHelper,
      CalendarDisplayType: CalendarDisplayType,
      modalAvailable: false
    };
  },
  computed: {
    ...mapStores(useCalendarStore, useWorkplacesStore, useLocationsStore),
    viewType(): CalendarResourceType {
      return this.calendarStore.viewType;
    },
    maxDisplayedLines(): number {
      if (this.customHeightInPx) {
        if (this.customHeightInPx <= 30) {
          return 0;
        } else if (this.customHeightInPx > 31 && this.customHeightInPx <= 45) {
          return 1;
        } else if (this.customHeightInPx > 46 && this.customHeightInPx <= 55) {
          return 2;
        } else if (this.customHeightInPx > 56 && this.customHeightInPx < 65) {
          return 3;
        }
      }
      return 4;
    },
    customerName(): string {
      return this.calendarEvent.summary
        ? null
        : this.calendarEvent.customer.firstname +
            ' ' +
            this.calendarEvent.customer.lastname;
    },
    lightText(): boolean {
      if (this.calendarEvent.workplace_id) {
        const workplace = this.getWorkplaceById(
          this.calendarEvent.workplace_id
        );
        return !ColorHelper.isColorLight(workplace.color, workplace.hue);
      }
      return false;
    },
    backgroundColor(): string {
      if (this.calendarEvent.workplace_id) {
        const workplace = this.getWorkplaceById(
          this.calendarEvent.workplace_id
        );
        return 'tw-bg-' + workplace.color + '-' + workplace.hue;
      }
      if (this.calendarEvent.location_id) {
        const location = this.getLocationById(this.calendarEvent.location_id);
        return 'tw-bg-' + location.color + '-200';
      }
      return 'tw-bg-white';
    },
    workplacesCount(): number {
      return this.workplacesStore.entities.length;
    },
    locationsCount(): number {
      return this.locationsStore.entities.length;
    }
  },
  methods: {
    getWorkplaceById(workplaceId: number) {
      return this.workplacesStore.getById(workplaceId);
    },
    getLocationById(locationId: number) {
      return this.locationsStore.getById(locationId);
    },
    cardClicked() {
      this.$bvModal.show('event-modal-' + this.zIndex);
    }
  }
});
