
















































import Vue from 'vue';

export default Vue.extend({
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    checkBoxOnTop: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSelection() {
      this.$emit('onSelectItem', !this.selected);
    }
  }
});
