






















































import OptionsInputMixin from '@/mixins/OptionsInputMixin';
import FormInputMixin from '@/mixins/FormInputMixin';
import { ValidationProvider } from 'vee-validate';
import Vue, { PropType, VueConstructor } from 'vue';
import { TranslateResult } from 'vue-i18n';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof FormInputMixin> &
    InstanceType<typeof OptionsInputMixin>
>).extend({
  components: { ValidationProvider },
  mixins: [FormInputMixin, OptionsInputMixin],
  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    /**
     * Pour un formulaire avec plusieurs éléments qui ont le même nom et qu'il faut gérer la validation API sur le nom
     * P. ex. EmployeeTimetableWeekday
     */
    namePrefix: {
      type: String,
      default: null
    },
    // Par défaut array of object qui contient label + value
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    errors: {
      type: Object,
      default: () => {}
    },
    inline: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: ''
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String as PropType<TranslateResult>,
      default: null
    },
    labelTooltip: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      model: this.value
    };
  },
  watch: {
    value: function(val) {
      this.model = this.value;
    }
  },
  methods: {
    onChange(event) {
      this.$emit('onValueChange', event.target.value);
    }
  }
});
