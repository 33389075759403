

































































































































import moment, { Moment } from 'moment';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import {
  CalendarDisplayType,
  CalendarEventsMap,
  CalendarSchedulesMap,
  CalendarSelectedMonth,
  CalendarViewMode,
  useCalendarStore
} from '@/store/calendar-store';
import { useUsersStore } from '@/store/users-store';
import { useEmployeesStore } from '@/store/employees-store';
import { API_DATE_FORMAT } from '@/constants';
import { Event } from '@/model/event';
import { useTimetablesStore } from '@/store/timetables-store';
import { Schedule } from '@/model/schedule';
import CalendarDayNumber from '@/components/calendar/CalendarDayNumber.vue';

export class CalendarDay {
  date: Moment;
  events: Event[];
  schedule: Schedule;
}

class CalendarWeek {
  days: CalendarDay[] = [];
}

class CalendarData {
  weeks: CalendarWeek[] = [];
}

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    LoadingIndicator,
    CalendarDayNumber
  },
  extends: CalendarBase,
  props: {
    alwaysExpanded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      weekdaysMin: moment.weekdaysMin(true),
      weekdays: moment.weekdays(true),
      API_DATE_FORMAT: API_DATE_FORMAT,
      calendar: new CalendarData(),
      CalendarViewMode: CalendarViewMode,
      isLgOrAbove: false as boolean,
      expanded: false as boolean
    };
  },
  computed: {
    ...mapStores(
      useCalendarStore,
      useUsersStore,
      useEmployeesStore,
      useTimetablesStore
    ),
    selectedMonth(): CalendarSelectedMonth {
      return this.calendarStore.selectedMonth;
    },
    selectedDate(): string {
      return this.calendarStore.selectedDate;
    },
    schedules(): CalendarSchedulesMap {
      return this.calendarStore.schedules.schedules;
    },
    events(): CalendarEventsMap {
      return this.calendarStore.events.events;
    },
    isLoading(): boolean {
      return this.calendarStore.schedules.fetching;
    },
    viewMode(): CalendarViewMode {
      return this.calendarStore.viewMode;
    },
    loggedInEmployeeId(): number {
      return this.usersStore.loggedInEmployeeId as number;
    },
    monthYearHumanized(): string {
      return `${moment.months()[this.selectedMonth.monthIndex]} ${
        this.selectedMonth.year
      }`;
    },
    currentWeekDates(): Moment[] {
      let currentDate = moment(this.selectedDate).startOf('week');
      const dates = [];
      for (let i = 0; i < 7; i++) {
        dates.push(currentDate);
        currentDate = currentDate.clone().add(1, 'day');
      }

      return dates;
    },
    mode(): 'day' | 'week' | 'weekMobile' | 'month' | null {
      if (this.mobileLandscape) {
        return 'weekMobile';
      } else if (
        (this.displayType === CalendarDisplayType.DAY_GRID ||
          this.displayType === CalendarDisplayType.PLANNING) &&
        !this.expanded
      ) {
        return 'day';
      } else if (
        this.displayType === CalendarDisplayType.WEEK_GRID &&
        !this.expanded
      ) {
        return 'week';
      } else if (this.expanded) {
        return 'month';
      }

      return null;
    }
  },
  created() {
    if (this.alwaysExpanded) {
      this.toggleExpand(true);
    }
    this.calendarStore.$subscribe(mutation => {
      /**
       * Dans le store on crée manuellement un $patch pour détecter le changement sur schedules ou events
       */
      if (
        // @ts-ignore
        typeof mutation.payload?.expanded === 'boolean' ||
        // @ts-ignore
        typeof mutation.payload?.schedules !== 'undefined' ||
        // @ts-ignore
        typeof mutation.payload?.events !== 'undefined' ||
        // @ts-ignore
        typeof mutation.payload?.selectedDate !== 'undefined'
      ) {
        this.createCalendar();
      }
    });
  },
  mounted() {
    if (this.isStaff) {
      this.employeesStore.setCurrentEntity(
        this.employeesStore.getById(this.loggedInEmployeeId)
      );
    } else if (this.isAdmin) {
      this.selectEmployee(this.loggedInEmployeeId);
    }
    this.createCalendar();
    //
    this.handleScreenOrientationChange();
    this.uiStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setScreenOrientation') {
          this.handleScreenOrientationChange();
        }
      });
    });
  },
  methods: {
    handleScreenOrientationChange() {
      if (!this.alwaysExpanded && this.expanded && this.mode === 'weekMobile') {
        this.toggleExpand(false);
      }
    },
    toggleExpand(expanded: boolean) {
      this.expanded = typeof expanded === 'boolean' ? expanded : !this.expanded;
      this.createCalendar();
    },
    createCalendar() {
      let firstDate: Moment;
      let lastDate: Moment;
      if (this.expanded) {
        firstDate = moment()
          .month(this.selectedMonth.monthIndex)
          .year(this.selectedMonth.year)
          .startOf('month')
          .startOf('week');
        lastDate = moment()
          .month(this.selectedMonth.monthIndex)
          .year(this.selectedMonth.year)
          .endOf('month')
          .endOf('week');
      } else {
        firstDate = moment(this.selectedDate).startOf('week');
        lastDate = moment(this.selectedDate).endOf('week');
      }
      this.calendar.weeks = [];
      for (
        const firstDateOfWeek = firstDate;
        firstDateOfWeek.isSameOrBefore(lastDate);
        firstDateOfWeek.add(1, 'week')
      ) {
        const days: CalendarDay[] = [];
        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
          const date: Moment = firstDateOfWeek.clone().add(dayIndex, 'day');
          days.push({
            date,
            events: this.calendarStore.getEvents(
              this.selectedEntityId,
              date.format(API_DATE_FORMAT)
            ),
            schedule: this.calendarStore.getSchedule(
              this.selectedEntityId,
              date.format(API_DATE_FORMAT)
            )
          });
        }
        this.calendar.weeks.push({ days });
      }
    },
    selectEmployee(employeeId: number) {
      this.timetablesStore.setSelectedEmployeeWithTimetableIfCurrentHasNot(
        employeeId
      );
    },
    next() {
      if ((this.mode === 'day' || this.mode === 'week') && !this.expanded) {
        this.calendarStore.changeDayOrWeek(1, this.mode);
      } else {
        this.calendarStore.changeMonth(1);
      }
      this.createCalendar();
    },
    previous() {
      if ((this.mode === 'day' || this.mode === 'week') && !this.expanded) {
        this.calendarStore.changeDayOrWeek(-1, this.mode);
      } else {
        this.calendarStore.changeMonth(-1);
      }
      this.createCalendar();
    },
    selectDate(day: CalendarDay) {
      this.calendarStore.selectDate(day.date.format(API_DATE_FORMAT));
      if (this.mode === 'week' && !this.expanded) {
        this.calendarStore.setDisplayType(CalendarDisplayType.DAY_GRID);
      }
    }
  }
});
