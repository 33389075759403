
































































import FormInputMixin from '@/mixins/FormInputMixin';
import { ValidationProvider } from 'vee-validate';
import '@/helpers/validation.helper';
import MaskedInput from 'vue-text-mask';
import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof FormInputMixin>
>).extend({
  components: {
    ValidationProvider,
    MaskedInput
  },
  mixins: [FormInputMixin],
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    /**
     * Pour un formulaire avec plusieurs éléments qui ont le même nom et qu'il faut gérer la validation API sur le nom
     * P. ex. EmployeeTimetableWeekday ou CustomerCloseBooking
     */
    namePrefix: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: '',
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    helpText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: () => {}
    },
    validationErrorMessage: {
      type: String,
      default: null
    },
    prependText: {
      // Valeur dans un input-prepend
      type: String,
      default: ''
    },
    appendText: {
      type: String,
      default: ''
    },
    appendTextColor: {
      type: String,
      default: ''
    },
    appendTextBgColor: {
      type: String,
      default: ''
    },
    optional: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: () => ''
    }
  },
  data: () => {
    return {
      model: null,
      mask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]
    };
  },
  watch: {
    model: function(val) {
      this.$emit('onValueChange', val);
    },
    value: function(val) {
      this.model = this.value;
    }
  },
  created() {
    this.model = this.value;
  },
  methods: {
    textInputClicked() {
      (this.$refs.maskedInput as any).$el.selectionStart = 0;
      (this.$refs.maskedInput as any).$el.selectionEnd = (this.$refs
        .maskedInput as any).$el.value.length;
    }
  }
});
