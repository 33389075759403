





























































































































































import moment from 'moment';
import Vue, { PropType } from 'vue';
import { Weekday } from '@/model/weekday';
import { Event } from '@/model/event';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { Location } from '@/model/location';
import { ComputedTimeRange } from '@/model/computed-time-range';
import { TimetableTimeRange } from '@/model/timetable-time-range';
import { TimetableTimeRangeException } from '@/model/timetable-time-range-exception';
import { TimetableDateException } from '@/model/timetable-date-exception';
import { Schedule } from '@/model/schedule';
import { useCalendarStore } from '@/store/calendar-store';
import { useEmployeesStore } from '@/store/employees-store';
import { MomentHelper } from '@/helpers/moment.helper';
import { useTimetableExceptionsStore } from '@/store/timetable-exceptions-store';
import sortBy from 'lodash/sortBy';
import { Timetable } from '@/model/timetable';
import { useTimetablesStore } from '@/store/timetables-store';

export default Vue.extend({
  props: {
    weekday: {
      type: Object as PropType<Weekday | TimetableDateException | Schedule>,
      required: true
    },
    displayWeekday: {
      type: Boolean,
      default: true
    },
    calendarMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moment: moment,
      capacitiesPerTimeRange: [] as number[],
      timeRanges: [] as
        | ComputedTimeRange[]
        | TimetableTimeRange[]
        | TimetableTimeRangeException[] // N'exclut pas les time ranges fermées en mode flexible
    };
  },
  computed: {
    ...mapStores(
      useLocationsStore,
      useCalendarStore,
      useEmployeesStore,
      useTimetableExceptionsStore,
      useTimetablesStore
    ),
    reduced(): boolean {
      return this.calendarStore.reduced;
    },
    locations(): Location[] {
      return this.locationsStore.entities;
    },
    rawTimeRanges():
      | ComputedTimeRange[]
      | TimetableTimeRange[]
      | TimetableTimeRangeException[] {
      return (
        (this.weekday as Schedule).timeRanges ??
        (this.weekday as Weekday).time_ranges ??
        (this.weekday as TimetableDateException).time_range_exceptions ??
        []
      );
    }
  },
  watch: {
    weekday() {
      this.setTimeRanges();
    }
  },
  mounted() {
    this.setTimeRanges();
  },
  methods: {
    async setTimeRanges() {
      if (
        this.calendarMode &&
        this.weekday.flexible &&
        this.weekday.capacity_threshold > 0
      ) {
        /**
         * On doit recréer toutes les time ranges, car si le capacity_threshold > 0, certains blocs peuvent ne pas être visibles
         */
        const schedule: Schedule = this.weekday as Schedule;
        this.timeRanges = this.rawTimeRanges;
        let timeRangesFromTimetable: Array<
          TimetableTimeRange | TimetableTimeRangeException
        > = [];
        if (schedule.resourceTableName === 'timetables') {
          let timetable: Timetable;
          timetable = this.timetablesStore.getById(schedule.resourceId);
          if (!timetable) {
            timetable = await this.timetablesStore.findOne(schedule.resourceId);
          }
          timeRangesFromTimetable =
            timetable.weekdays[
              moment(this.calendarStore.selectedDate).weekday()
            ].time_ranges;
        } else {
          let timetableException: TimetableDateException;
          timetableException = this.timetableExceptionsStore.getById(
            schedule.resourceId
          );
          if (!timetableException) {
            timetableException = await this.timetableExceptionsStore.findOne(
              schedule.resourceId
            );
          }
          timeRangesFromTimetable = timetableException.time_range_exceptions;
        }
        const notOpenTimeRanges = timeRangesFromTimetable.filter(
          timeRangeFromTimetable => {
            return !this.timeRanges.some(timeRange => {
              return timeRangeFromTimetable.position === timeRange.position;
            });
          }
        );
        this.timeRanges = []
          .concat(this.rawTimeRanges)
          .concat(notOpenTimeRanges);
        this.timeRanges = sortBy(this.timeRanges, 'position');
        /**
         * Calcul du pourcentage actuel de remplissage du bloc
         */
        const events: Event[] = await this.calendarStore.getEvents(
          this.employeesStore.currentEntity.id,
          this.calendarStore.selectedDate
        );
        this.capacitiesPerTimeRange = [];
        this.timeRanges.forEach(timeRange => {
          const eventsInTimeRange: Event[] = events.filter(event => {
            return MomentHelper.checkIfTimeRangesOverlap(
              moment(timeRange.start),
              moment(timeRange.end),
              moment(event.start),
              moment(event.end)
            );
          });
          const totalEventsMinutes: number = eventsInTimeRange.reduce(
            (accumulator: number, event: Event) => {
              return (
                accumulator +
                moment(event.end).diff(moment(event.start), 'minutes')
              );
            },
            0
          );
          const timeRangeDuration = moment(timeRange.duration).diff(
            moment(timeRange.duration)
              .hours(0)
              .minutes(0)
              .seconds(0),
            'minutes'
          );
          this.capacitiesPerTimeRange.push(
            Math.floor((totalEventsMinutes / timeRangeDuration) * 100)
          );
        });
      } else {
        this.timeRanges = this.rawTimeRanges;
      }
    },
    isTimeRangeOpen(
      timeRange:
        | ComputedTimeRange
        | TimetableTimeRange
        | TimetableTimeRangeException
    ) {
      return timeRange.start !== null;
    },
    /**
     * Retourne true si le bloc d'horaire flexible est figé
     */
    isTimeRangeLocked(
      timeRange:
        | ComputedTimeRange
        | TimetableTimeRange
        | TimetableTimeRangeException
    ) {
      return (
        moment(timeRange.end).diff(timeRange.start, 'minutes') ===
        moment(timeRange.duration).diff(
          moment(timeRange.duration)
            .hours(0)
            .minutes(0)
            .seconds(0),
          'minutes'
        )
      );
    }
  }
});
