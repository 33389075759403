var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-bg-gray-100 tw-z-0 tw-h-12"},[_vm._l((_vm.calendarEvents),function(calendarEvent,i){return _c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getSummary(calendarEvent)),expression:"getSummary(calendarEvent)",modifiers:{"hover":true,"top":true}}],key:i,staticClass:"tw-absolute tw-z-10 tw-h-full tw-cursor-pointer",class:[
      {
        'tw-text-light': calendarEvent.workplace_id
          ? !_vm.ColorHelper.isColorLight(
              _vm.getWorkplaceById(calendarEvent.workplace_id).color,
              _vm.getWorkplaceById(calendarEvent.workplace_id).hue
            )
          : false
      },
      calendarEvent.workplace_id
        ? ("tw-bg-" + (_vm.getWorkplaceById(calendarEvent.workplace_id).color) + "-" + (_vm.getWorkplaceById(calendarEvent.workplace_id).hue))
        : 'tw-bg-gray-400'
    ],style:({
      width: _vm.getItemWidth(calendarEvent) + '%',
      left: _vm.getItemLeftOffset(calendarEvent) + '%'
    }),attrs:{"title":_vm.getStartEndHumanized(calendarEvent)},on:{"click":function($event){return _vm.openDate()}}})}),_vm._l((_vm.getUnavailableTimeSlots(_vm.dateProp, _vm.employee)),function(slot){return _c('div',{key:slot.start.toISOString(),staticClass:"tw-absolute tw-bg-gray-200 tw-z-1 tw-h-full",style:({
      width: _vm.getItemWidth(slot) + '%',
      left: _vm.getItemLeftOffset(slot) + '%'
    })})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }