






































// Classe is-invalid cause problème => pas utilisée pour le moment (radios pas en rouge)
import FormInputMixin from '@/mixins/FormInputMixin';
import OptionsInputMixin from '@/mixins/OptionsInputMixin';
import Vue, { PropType, VueConstructor } from 'vue';
import { InputOption } from '@/model/input-option';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof FormInputMixin> &
    InstanceType<typeof OptionsInputMixin>
>).extend({
  mixins: [FormInputMixin, OptionsInputMixin],
  props: {
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    /**
     * Pour un formulaire avec plusieurs éléments qui ont le même nom et qu'il faut gérer la validation API sur le nom
     * P. ex. TimetableFormWeekday
     */
    namePrefix: {
      type: [String, Number],
      default: null
    },
    // Par défaut array of object qui contient value + label
    options: {
      type: Array as PropType<InputOption[]>,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    defaultValue: {
      type: [String, Number, Boolean],
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: this.defaultValue
    };
  },
  watch: {
    defaultValue: function() {
      this.setValue();
    }
  },
  created() {
    this.setValue();
  },
  methods: {
    setValue() {
      if (typeof this.defaultValue === 'boolean') {
        this.value = this.defaultValue ? '1' : '0';
      }
    },
    onChange(event) {
      this.$emit(
        'onValueChange',
        this.castValueAccordingToValueDataType(event.target.value)
      );
    }
  }
});
