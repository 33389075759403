




































































































import Vue, { VueConstructor } from 'vue';
import {
  CalendarViewMode,
  CalendarResourceType,
  useCalendarStore
} from '@/store/calendar-store';
import { Employee } from '@/model/employee';
import { Machine } from '@/model/machine';
import { Workplace } from '@/model/workplace';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import { mapStores } from 'pinia';
import { useMachinesStore } from '@/store/machines-store';
import { useWorkplacesStore } from '@/store/workplaces-store';
import { InputOption } from '@/model/input-option';
import { useEmployeesStore } from '@/store/employees-store';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  mixins: [CalendarBase],
  data() {
    return {
      CalendarViewMode: CalendarViewMode,
      CalendarViewType: CalendarResourceType
    };
  },
  computed: {
    ...mapStores(
      useCalendarStore,
      useMachinesStore,
      useWorkplacesStore,
      useEmployeesStore
    ),
    viewType(): CalendarResourceType {
      return this.calendarStore.viewType;
    },
    viewMode(): CalendarViewMode {
      return this.calendarStore.viewMode;
    },
    employee(): Employee {
      return this.employeesStore.getById(this.selectedEmployeeId);
    },
    machine(): Machine {
      return this.machinesStore.getById(this.selectedMachineId);
    },
    workplace(): Workplace {
      return this.workplacesStore.getById(this.selectedWorkplaceId);
    },
    employeesAsSelectOptions(): InputOption[] {
      return this.employeesStore.getAllAsSelectOptions();
    },
    workplacesAsSelectOptions(): InputOption[] {
      return this.workplacesStore.getWorkplacesAsSelectOptions();
    },
    machinesAsSelectOptions(): InputOption[] {
      return this.machinesStore.getMachinesAsSelectOptions();
    }
  },
  methods: {
    setViewMode(viewMode: CalendarViewMode) {
      this.calendarStore.setViewMode(viewMode);
    },
    selectEmployee(employeeId: number) {
      this.employeesStore.setCurrentEntity(
        this.employeesStore.getById(employeeId)
      );
      this.setViewMode(CalendarViewMode.PERSONAL);
    },
    selectWorkplace(workplaceId: number) {
      this.workplacesStore.setCurrentEntity(
        this.workplacesStore.getById(workplaceId)
      );
      this.setViewMode(CalendarViewMode.PERSONAL);
    },
    selectMachine(machineId: number) {
      this.machinesStore.setCurrentEntity(
        this.machinesStore.getById(machineId)
      );
      this.setViewMode(CalendarViewMode.PERSONAL);
    }
  }
});
