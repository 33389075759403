





























































import Vue, { PropType, VueConstructor } from 'vue';
import { CalendarDay } from '@/components/calendar/CalendarWidget.vue';
import moment from 'moment';
import CalendarBase from '@/components/shared/CalendarBase.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  mixins: [CalendarBase],
  props: {
    day: {
      type: Object as PropType<CalendarDay>,
      required: true
    },
    clickable: {
      type: Boolean,
      default: false
    },
    showWeekday: {
      type: Boolean,
      default: false
    },
    highlightSelectedDate: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    weekday(): string {
      return moment.weekdaysMin(true)[this.day.date.weekday()];
    }
  },
  methods: {
    isToday(day: CalendarDay): boolean {
      return moment().isSame(day.date, 'day');
    },
    isSelectedDay(day: CalendarDay): boolean {
      return day.date.isSame(this.date, 'day');
    }
  }
});
