var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.viewType === _vm.CalendarViewType.EMPLOYEE && _vm.employee)?_c('b-dropdown',{attrs:{"variant":"secondary","html":'<span class=\'tw-truncate tw-pt-1 tw-w-12 sm:tw-w-auto tw-inline-block tw-leading-none\'>' +
        (_vm.viewMode === _vm.CalendarViewMode.PERSONAL
          ? _vm.employee.firstname + ' ' + _vm.employee.lastname
          : _vm.$t('label.all')) +
        '</span>'}},[_c('b-dropdown-item',{attrs:{"active":_vm.viewMode === _vm.CalendarViewMode.TEAM},on:{"click":function($event){return _vm.setViewMode(_vm.CalendarViewMode.TEAM)}}},[_vm._v(" "+_vm._s(_vm.$t('label.all'))+" ")]),_c('b-dropdown-divider'),_vm._l((_vm.employeesAsSelectOptions),function(employeeOption){return _c('b-dropdown-item',{key:employeeOption.value,attrs:{"active":employeeOption.value === _vm.selectedEmployeeId &&
          _vm.viewMode === _vm.CalendarViewMode.PERSONAL},on:{"click":function($event){return _vm.selectEmployee(employeeOption.value)}}},[_vm._v(" "+_vm._s(employeeOption.label)+" ")])})],2):_vm._e(),(_vm.viewType === _vm.CalendarViewType.WORKPLACE && _vm.workplace)?_c('b-dropdown',{attrs:{"variant":"secondary","html":'<span class=\'tw-truncate tw-pt-1 tw-w-12 sm:tw-w-auto tw-inline-block tw-leading-none\'>' +
        (_vm.viewMode === _vm.CalendarViewMode.PERSONAL
          ? _vm.workplace.location.short_name + ' - ' + _vm.workplace.name
          : _vm.$t('label.all')) +
        '</span>'}},[_c('b-dropdown-item',{attrs:{"active":_vm.viewMode === _vm.CalendarViewMode.TEAM},on:{"click":function($event){return _vm.setViewMode(_vm.CalendarViewMode.TEAM)}}},[_vm._v(" "+_vm._s(_vm.$t('label.all'))+" ")]),_c('b-dropdown-divider'),_vm._l((_vm.workplacesAsSelectOptions),function(workplaceOption){return _c('b-dropdown-item',{key:workplaceOption.value,attrs:{"active":workplaceOption.value === _vm.selectedWorkplaceId &&
          _vm.viewMode === _vm.CalendarViewMode.PERSONAL},on:{"click":function($event){return _vm.selectWorkplace(workplaceOption.value)}}},[_vm._v(" "+_vm._s(workplaceOption.label)+" ")])})],2):_vm._e(),(_vm.viewType === _vm.CalendarViewType.MACHINE && _vm.machine)?_c('b-dropdown',{attrs:{"variant":"secondary","html":'<span class=\'tw-truncate tw-pt-1 tw-w-12 sm:tw-w-auto tw-inline-block tw-leading-none\'>' +
        (_vm.viewMode === _vm.CalendarViewMode.PERSONAL
          ? _vm.machine.name
          : _vm.$t('label.allFeminine')) +
        '</span>'}},[_c('b-dropdown-item',{attrs:{"active":_vm.viewMode === _vm.CalendarViewMode.TEAM},on:{"click":function($event){return _vm.setViewMode(_vm.CalendarViewMode.TEAM)}}},[_vm._v(" "+_vm._s(_vm.$t('label.allFeminine'))+" ")]),_c('b-dropdown-divider'),_vm._l((_vm.machinesAsSelectOptions),function(machineOption){return _c('b-dropdown-item',{key:machineOption.value,attrs:{"active":machineOption.value === _vm.selectedMachineId &&
          _vm.viewMode === _vm.CalendarViewMode.PERSONAL},on:{"click":function($event){return _vm.selectMachine(machineOption.value)}}},[_vm._v(" "+_vm._s(machineOption.label)+" ")])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }