var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.rules,"name":_vm.namePrefix + _vm.name,"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('div',{staticClass:"form-group"},[(_vm.label)?_c('label',{attrs:{"for":_vm.namePrefix + _vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input-group"},[(_vm.prependText)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.prependText))])]):_vm._e(),_c('masked-input',{ref:"maskedInput",staticClass:"form-control",class:{
          'is-invalid': _vm.isInvalid || v.errors.length > 0
        },attrs:{"id":_vm.namePrefix + _vm.name,"type":"tel","name":_vm.namePrefix + _vm.name,"placeholder":"HH:MM","disabled":_vm.disabled,"mask":_vm.mask,"show-mask":false,"guide":true,"placeholder-char":"_"},on:{"click":_vm.textInputClicked},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),(_vm.appendText)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",class:[
            _vm.appendTextColor !== ''
              ? 'text-' + _vm.appendTextColor
              : _vm.appendTextColor,
            _vm.appendTextBgColor !== ''
              ? 'bg-' + _vm.appendTextBgColor
              : _vm.appendTextBgColor
          ]},[_vm._v(" "+_vm._s(_vm.appendText)+" ")])]):_vm._e()],1),(_vm.errorMessage)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(v.errors[0])?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(v.errors[0])+" ")]):_vm._e(),(_vm.helpText)?_c('small',{staticClass:"form-text text-muted"},[_c('i',{staticClass:"fas fa-info-circle mr-2 mt-1"}),_c('span',[_vm._v(_vm._s(_vm.helpText))])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }