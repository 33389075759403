































































































import CalendarResourceSelector from '@/components/calendar/CalendarResourceSelector.vue';
import Vue, { VueConstructor } from 'vue';
import CalendarBase from '@/components/shared/CalendarBase.vue';
import {
  CalendarDisplayType,
  CalendarViewMode,
  useCalendarStore
} from '@/store/calendar-store';
import DateInput from '@/components/shared/forms/DateInput.vue';
import moment from 'moment';
import { API_DATE_FORMAT } from '@/constants';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { useEmployeesStore } from '@/store/employees-store';
import CalendarSettingsModal from '@/components/calendar/CalendarSettingsModal.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof CalendarBase>
>).extend({
  components: {
    CalendarResourceSelector,
    DateInput,
    CalendarSettingsModal
  },
  extends: CalendarBase,
  data() {
    return {
      CalendarDisplayType: CalendarDisplayType,
      VIEW_MODE: CalendarViewMode,
      today: moment().format(API_DATE_FORMAT)
    };
  },
  computed: {
    ...mapStores(useUsersStore, useCalendarStore, useEmployeesStore),
    loggedInEmployeeId(): number {
      return this.usersStore.loggedInEmployeeId as number;
    }
  },
  methods: {
    setDate(date: string) {
      this.calendarStore.selectDate(date);
      this.calendarStore.setMonthYear({
        monthIndex: moment(date).month(),
        year: moment(date).year()
      });
      this.$bvModal.hide('goToDateModal');
    },
    refresh() {
      this.employeesStore.setCurrentEntity(this.employeesStore.currentEntity);
    }
  }
});
