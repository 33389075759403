
























import padStart from 'lodash/padStart';
import Vue from 'vue';
import { useCalendarStore } from '@/store/calendar-store';
import { mapStores } from 'pinia';
import VueSlider from 'vue-slider-component';

export default Vue.extend({
  components: { VueSlider },
  data() {
    return {
      hours: [] as number[],
      value: 8
    };
  },
  computed: {
    ...mapStores(useCalendarStore),
    startOfDay(): number {
      return this.calendarStore.startOfDay;
    }
  },
  created() {
    this.value = this.startOfDay;
    this.calendarStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'setStartOfDay') {
          this.value = this.startOfDay;
        }
      });
    });
    for (let i = 0; i <= 24; i++) {
      this.hours.push(i);
    }
  },
  methods: {
    tooltipFormatter(value: string): string {
      return padStart(value, 2, '0') + ':00';
    },
    valueChanged(value: number) {
      this.calendarStore.setStartOfDay(value);
    }
  }
});
