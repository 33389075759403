




import moment from 'moment';
import { TIME_FORMAT } from '@/filters/time';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useLocationsStore } from '@/store/locations-store';
import { InputOption } from '@/model/input-option';

export default Vue.extend({
  data() {
    return {
      availableTimes: []
    };
  },
  computed: {
    ...mapStores(useLocationsStore),
    locationsAsSelectOptions(): InputOption[] {
      return this.locationsStore.getLocationsAsSelectOptions;
    }
  },
  created() {
    for (let hours = 8; hours < 21; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        this.availableTimes.push({
          value: moment()
            .hours(hours)
            .minutes(minutes)
            .format(TIME_FORMAT),
          label: moment()
            .hours(hours)
            .minutes(minutes)
            .format(TIME_FORMAT)
        });
      }
    }
  },
  methods: {
    /**
     * Un créneau ne peut pas débuter avant un créneau précédent (ex : créneau 1 de 08h00 à 12h00, le créneau 2 ne peut
     * pas commencer avant 12h00)
     *
     * @param timeRangeIndex
     * @param timeRanges Tous les créneaux de la journée
     * @returns {Array|*[]}
     */
    getAvailableTimesForTimeRange(timeRangeIndex, timeRanges) {
      if (timeRangeIndex === 0) {
        return this.availableTimes;
      } else {
        const endOfLastTimeRange = moment(
          timeRanges[timeRangeIndex - 1].end,
          TIME_FORMAT
        );
        if (endOfLastTimeRange.isValid()) {
          return this.availableTimes.filter(timeOptions => {
            return moment(timeOptions.value, TIME_FORMAT).isSameOrAfter(
              endOfLastTimeRange
            );
          });
        } else {
          return this.availableTimes;
        }
      }
    }
  }
});
